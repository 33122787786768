import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from 'sweetalert';
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import DatePicker from 'react-datepicker';
import addMonths from 'date-fns/addMonths'
import "react-datepicker/dist/react-datepicker.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import moment from "moment";
const user = JSON.parse(localStorage.getItem('user'));
// Get a date object for the current time
var aMonth = new Date();

// Set it to one month ago
aMonth.setMonth(aMonth.getMonth() - 1);
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class MSRGenerate extends Component {
    constructor(props) {
        super(props);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            generateHotel: '',
            generateDate: '',
            hotelModalName: '',
            hotelId: '',
            date: '',
            dsrExcel: '',
            dsrPdf: '',
            waitDownload: '',
            unitData: [],
            logGenerateDsr: [],
            showAdminHotel: false,
            currentUser: undefined,
            theMonth: aMonth,
        };
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.setState({
            hotelModalName: e.target[e.target.selectedIndex].text
        })
        console.log(e.target.value)
    }
    handleChangeMonth = (date) => {
        const dateMonth = moment(date).format("YYYYMM").toLocaleString();
        this.setState({
            theMonth: date,
            date: dateMonth
        })
        console.log(moment(date).format("YYYYMM").toLocaleString())
        console.log(this.state.theMonth)
    }
    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
            hotelId: val
        });
    }

    handleUpdate = (e) => {
        axios.get(http.getBaseURL() + 'reports/logs', { headers: authHeader() })
            .then(res => {
                this.setState({ logGenerateDsr: res.data.data });
            })
            .catch(function (error) {
            });
    }


    handleGenerate = event => {
        this.setState({
            waitDownload: '',
        })
        const user = AuthService.getCurrentUser();
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.access_token,
            },
        };

        if (user.hotel) {
            this.setState({
                hotelId: user.hotel.id,
                date: this.state.date,
            })
        } else {
            this.setState({
                hotelId: this.state.hotelId,
                date: this.state.date,
            })
        }

        //const url = http.getBaseURL() + 'reports/daily-reports';
        axios.get(http.getBaseURL() + 'reports/monthly-reports/' + this.state.hotelId + '/' + this.state.date, config)
            .then(res => {
                this.setState({
                    waitDownload: res.data.message,
                    dsrExcel: res.data.data.excel,
                    dsrPdf: res.data.data.pdf,
                })
            })
            .catch((error) => {
            });
    }


    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                showAdminHotel: user.role.name.includes('Admin Hotel'),
            });
        }
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
            })
            .catch(function (error) {
            });

        axios.get(http.getBaseURL() + 'reports/logs/v2?reportTypeId=2', { headers: authHeader() })
            .then(res => {
                this.setState({ logGenerateDsr: res.data.data });
            })
            .catch(function (error) {
            });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable({
                    aaSorting: []
                });
            }, 1000);
        });
        $(function () {
            var dtToday = new Date();
            var month = dtToday.getMonth();


            var year = dtToday.getFullYear();

            if (month < 10)
                month = '0' + month.toString();


            var maxDate = year + '-' + month;
            $('#txtDate').attr('max', maxDate);
        });

    }

    render() {
        const { showAdminHotel, currentUser } = this.state;
        return (

            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Generate Kertas Kerja Bulanan</span>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Generate Kertas Kerja Bulanan</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner">
                    <div className="container-fluid plr_30 body_white_bg pt_30">

                        <form onSubmit={this.handleGenerate} >
                            <div className="row">
                                {showAdminHotel ?
                                    <div className="col-4" style={{ padding: "15px" }}>
                                        <div className="form-page mb-3">
                                            <label for="exampleInputPassword1" className="form-label">Unit</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={currentUser.hotel.name}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    : (
                                        <div className="col-4" style={{ padding: "15px" }}>
                                            <div className="form-page mb-3">
                                                <label for="exampleInputPassword1" className="form-label"> Unit</label>
                                                <select
                                                    className="form-control"
                                                    name="hotelId"
                                                    onChange={this.handleChange}

                                                >
                                                    <option>Pilih Unit</option>
                                                    {this.state.unitData?.map((result) => {
                                                        return (
                                                            <option value={result.id} name={result.name}>{result.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>


                                        </div>
                                    )}

                                <div className="col-4" style={{ padding: "15px" }}>


                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label"> Month</label>
                                        <DatePicker
                                            selected={this.state.theMonth}
                                            placeholderText="Silakan Pilih Bulan"
                                            onChange={this.handleChangeMonth}
                                            name="theMonth"
                                            dateFormat="MMMM , yyyy"
                                            maxDate={addMonths(new Date(), -1)}
                                            showMonthYearPicker
                                            className="form-control"
                                        />
                                    </div>


                                </div>
                                <div className="col-4" style={{
                                    padding: "45px 15px"
                                }}>
                                    <button type="submit" className="btn btn-primary" style={{ width: "100%" }} data-bs-toggle="modal" data-bs-target="#modal-download" >Generate</button>
                                </div>
                            </div>
                        </form>
                        <div className="row justify-content-center">
                            <div className="col-12">

                                <div className="QA_section">
                                    <div className="white_box_tittle list_header">
                                        <h4>Log Kertas Kerja Bulanan</h4>
                                        <div className="box_right d-flex lms_block">
                                        </div>
                                    </div>
                                    <div className="QA_table" style={{ overflow: "scroll" }}>
                                        <table id="example" className="table lms_table_active">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Generate Time</th>
                                                    <th scope="col">Unit</th>
                                                    <th scope="col">Generate By</th>
                                                    <th scope="col">MSR MONTH</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.logGenerateDsr.map((result, i) => {
                                                    return (

                                                        <tr key={i}>

                                                            <td>
                                                                {moment(result.exportDate).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </td>
                                                            <td>{result.hotelName}</td>
                                                            <td>{result.userName}</td>
                                                            <td>{result.period}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal modal-blur fade" id="modal-download" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        {this.state.hotelId && this.state.date ? (
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Monthly Sales Report | {this.state.hotelModalName} | {this.state.date}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container mt-3 MB-3">
                                        {this.state.waitDownload ? (
                                            <div className="col-xl-12">
                                                <div className="row text-center" style={{ margin: "20px" }}>
                                                    <p style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}>Silakan Unduh Monthly Sales Report dibawah ini</p>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-6">
                                                        <a href={this.state.dsrPdf} target="_blank" onClick={() => this.handleUpdate()} style={{ width: "100%" }} className="btn btn-download-pdf btn-download btn-danger " >
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> PDF
                                                        </a>
                                                    </div>
                                                    <div className="col-6">
                                                        <a href={this.state.dsrExcel} target="_blank" onClick={() => this.handleUpdate()} style={{ width: "100%" }} className="btn btn-download-excel btn-download btn-success " >
                                                            <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXCEL
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-xl-12">

                                                <div className="row text-center">
                                                    <div class="loader"></div>
                                                </div>
                                                <div className="row text-center" style={{ margin: "20px" }}>
                                                    <p style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}>Mohon Tunggu Sampai Proses Kalkulasi Selesai</p>
                                                    <span>Jangan Tutup Halaman Pop-Up ini</span>
                                                </div>

                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                        ) : (
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Monthly Sales Report  {this.state.hotelModalName}  {this.state.date}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container mt-3 MB-3">
                                        <div className="col-xl-12">
                                            <div className="row text-center" style={{ margin: "20px" }}>
                                                <p style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}>Unit dan Bulan Harus dipilih terlebih dahulu</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                    </div>
                </div >
            </>
        );
    }
}
