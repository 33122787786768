import React, { Component } from "react";
import Navbar from "./layouts/navbar.component";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import axios from "axios";
import http from "../services/http";
import SlideGCB from "./pages/dashboards/gcb.component";
import SlideGCR from "./pages/dashboards/gcr.component";
import SlideCOP from "./pages/dashboards/cop.component";
import SlideCOC from "./pages/dashboards/coc.component";
import SlideDAP from "./pages/dashboards/dap.component";
import SlideDAH from "./pages/dashboards/dah.component";
import SlideDAS from "./pages/dashboards/das.component";
import SlideMain from "./pages/dashboards/main.component";
import SlideEORDER from "./pages/dashboards/e-order.component";
import SlideOTA from "./pages/dashboards/ota.component";
import SlideSegment from "./pages/dashboards/segment.component";
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.handleChangeDashboard = this.handleChangeDashboard.bind(this);
    this.state = {
      dashboardView: "",
      unitData: [],
      currentUser: undefined,
      showAdminHotel: false,
    };
  }

  handleChangeDashboard = (e) => {
    this.setState({
      dashboardView: e.target.value,
    });
  };

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        showAdminHotel: user.role.name.includes("Admin Hotel"),
        dashboardView: user.role.name.includes("Admin Hotel")
          ? user.hotel.id
          : 0,
      });
    }

    const url = http.getBaseURL() + "references/hotels";
    axios
      .get(url)
      .then((res) => {
        this.setState({ unitData: res.data.data });
      })
      .catch(function (error) {});
  }

  render() {
    const { showAdminHotel, currentUser } = this.state;
    return (
      <>
        <div className="container-fluid g-0">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="header_iner d-flex justify-content-between align-items-center">
                <div className="sidebar_icon d-lg-none  ">
                  <i className="ti-menu"></i>
                  <span
                    className="d-md-none"
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      margin: "0 10px",
                    }}
                  >
                    DashBoard
                  </span>
                </div>
                <div className="serach_field-area">
                  <div className="search_inner">
                    <h2>DashBoard</h2>
                  </div>
                </div>

                <Navbar />
              </div>
            </div>
          </div>
        </div>
        <div className="main_content_iner ">
          <div
            className="container-fluid plr_30 body_white_bg "
            style={{ paddingTop: "10px" }}
          >
            {showAdminHotel ? null : (
              <div className="row text-center" style={{ margin: "auto" }}>
                <select
                  className="form-control"
                  name="paymentTypeId"
                  onChange={this.handleChangeDashboard}
                >
                  <option value={0}>Rangkuman Data Unit</option>

                  {this.state.unitData.map((result, i) => {
                    return (
                      <option key={i} value={result.id}>
                        {result.name}
                      </option>
                    );
                  })}

                  <option value={101}>E-Order BPPBJ</option>
                  <option value={102}>OTA Today Summary</option>
                  <option value={103}>Customer Segmentation</option>
                </select>
              </div>
            )}

            <div className="row justify-content-center">
              {this.state.dashboardView == 0 ? <SlideMain /> : null}
              {this.state.dashboardView == 1 ? <SlideGCB /> : null}
              {this.state.dashboardView == 2 ? <SlideGCR /> : null}
              {this.state.dashboardView == 3 ? <SlideCOC /> : null}
              {this.state.dashboardView == 4 ? <SlideCOP /> : null}
              {this.state.dashboardView == 5 ? <SlideDAH /> : null}
              {this.state.dashboardView == 6 ? <SlideDAS /> : null}
              {this.state.dashboardView == 7 ? <SlideDAP /> : null}
              {this.state.dashboardView == 101 ? <SlideEORDER /> : null}
              {this.state.dashboardView == 102 ? <SlideOTA /> : null}
              {this.state.dashboardView == 103 ? <SlideSegment /> : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
