import React, { Component } from 'react';

import { NumericFormat } from 'react-number-format'; import axios from 'axios';
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import http from '../../../services/http';
import moment from "moment";
export default class SlideOTA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dgcb: [],
            dgcr: [],
            dacp: [],
            dahs: [],
            dapl: [],
            dcop: [],
            dccp: [],

            revgcb: "0",
            revgcr: "0",
            revacp: "0",
            revahs: "0",
            revapl: "0",
            revcop: "0",
            revccp: "0",

            roomgcb: "0",
            roomgcr: "0",
            roomacp: "0",
            roomahs: "0",
            roomapl: "0",
            roomcop: "0",
            roomccp: "0",

            revenue: "0",
            roomnight: "0"
        };
    }


    detailData(hotelID, dataRoomRevenue, dataRoomNight, dataPerHotel, labelOtaPerHotel) {
        const config = {
            headers: {
                'x-app-key': 'PTJAKTOURJXBPTJAKTOURJXBPTJAKTOURJXB'
            }
        };
        var roomRev = 0;
        var roomNight = 0;
        var num = 0;
        var num2 = 0;
        var otaName = "";
        var otaRev = "";
        const url = http.getBaseURL() + 'daily/ota?filter=today&hotelId=' + hotelID;
        axios.get(url, config)
            .then(res => {

                res.data.data.map((result, i) => {
                    return (
                        num = Number(result.TotalRoomRev),
                        num2 = Number(result.TotalRoomNights),
                        roomRev += num,
                        roomNight += num2
                    )
                });

                otaName = res.data.data.map((result, i) => {
                    return (
                        result.OTAName
                    )
                });

                otaRev = res.data.data.map((result, i) => {
                    return (
                        result.TotalRoomRev
                    )
                });


                this.setState({
                    [dataRoomNight]: roomNight,
                    [dataRoomRevenue]: roomRev,
                    [dataPerHotel]: otaRev,
                    [labelOtaPerHotel]: otaName,
                });

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    totalData() {
        const config = {
            headers: {
                'x-app-key': 'PTJAKTOURJXBPTJAKTOURJXBPTJAKTOURJXB'
            }
        };
        var roomRev = 0;
        var roomNight = 0;
        var num = 0;
        var num2 = 0;
        const url = http.getBaseURL() + 'daily/ota?filter=today';
        axios.get(url, config)
            .then(res => {
                res.data.data.map((result, i) => {
                    return (
                        num = Number(result.TotalRoomRev),
                        num2 = Number(result.TotalRoomNights),
                        roomRev += num,
                        roomNight += num2
                    )
                });

                var otaName = res.data.data.map((result, i) => {
                    return (
                        result.OTAName
                    )
                });

                var otaRev = res.data.data.map((result, i) => {
                    return (
                        result.TotalRoomRev
                    )
                });

                this.setState({
                    revenue: roomRev,
                    roomnight: roomNight,
                    todayOtaName: otaName,
                    todayOtaRev: otaRev
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    componentDidMount() {
        this.detailData(1, "revgcb", "roomgcb", "keepgcb", "labelgcb");
        this.detailData(2, "revgcr", "roomgcr", "keepgcr", "labelgcr");
        this.detailData(3, "revccp", "roomccp", "keepccp", "labelccp");
        this.detailData(4, "revcpl", "roomcpl", "keepcpl", "labelcpl");
        this.detailData(5, "revacp", "roomacp", "keepacp", "labelacp");
        this.detailData(6, "revahs", "roomahs", "keepahs", "labelahs");
        this.detailData(7, "revapl", "roomapl", "keepapl", "labelapl");
        this.totalData();
        this.interval = setInterval(() => {
            this.totalData();
            this.detailData(1, "revgcb", "roomgcb", "keepgcb", "labelgcb");
            this.detailData(2, "revgcr", "roomgcr", "keepgcr", "labelgcr");
            this.detailData(3, "revccp", "roomccp", "keepccp", "labelccp");
            this.detailData(4, "revcpl", "roomcpl", "keepcpl", "labelcpl");
            this.detailData(5, "revacp", "roomacp", "keepacp", "labelacp");
            this.detailData(6, "revahs", "roomahs", "keepahs", "labelahs");
            this.detailData(7, "revapl", "roomapl", "keepapl", "labelapl");
        }, 180000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        console.log(this.state.todayOtaName);
        console.log(this.state.todayOtaRev);

        const { revgcb, revgcr, revccp, revcpl, revacp, revahs, revapl, roomgcb, roomgcr, roomccp, roomcpl, roomahs, roomapl, roomacp } = this.state;
        const labelChart1 = ["GCB", "GCR", "CCP", "CPL", "ACP", "AHS", "APL"]
        const labelgcb = this.state.labelgcb;
        const labelgcr = this.state.labelgcr;
        const labelccp = this.state.labelccp;
        const labelcpl = this.state.labelcpl;
        const labelacp = this.state.labelacp;
        const labelahs = this.state.labelahs;
        const labelapl = this.state.labelapl;
        const arrayColor = ["rgb(0, 121, 255)", "rgb(0, 223, 162)", "rgb(246, 250, 112)", "rgb(255, 0, 96)", "rgb(194, 222, 220)", "rgb(236, 229, 199)", "rgb(205, 194, 174)", "rgb(17, 106, 123)", "rgb(83, 145, 101)", "rgb(63, 73, 127)", "rgb(63, 73, 127)", "rgb(247, 192, 74)", "rgb(236, 114, 114)", "rgb(247, 167, 108)", "rgb(247, 167, 108)", "rgb(224, 217, 140)", "rgb(195, 255, 153)", "rgb(199, 128, 250)", "rgb(250, 218, 157)", "rgb(227, 172, 249)"];

        const dataChart1 = {
            labels: labelChart1,
            datasets: [
                {
                    lineTension: 0.5,
                    label: "Revenue",
                    backgroundColor: 'rgb(33, 51, 99)',
                    borderColor: "rgb(33, 51, 99)",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'rgb(33, 51, 99)',
                    pointBackgroundColor: "rgb(33, 51, 99)",
                    data: [revgcb, revgcr, revccp, revcpl, revacp, revahs, revapl],
                },
            ],
        };

        const dataChart3 = {
            labels: this.state.todayOtaName,
            datasets: [
                {
                    lineTension: 0.5,
                    label: "Revenue",
                    backgroundColor: "rgb(17, 106, 123)",
                    borderColor: "rgb(17, 106, 123)",
                    borderWidth: 3,
                    pointStyle: 'square',
                    pointRadius: 5,
                    pointBorderColor: "rgb(17, 106, 123)",
                    pointBackgroundColor: "rgb(17, 106, 123)",
                    data: this.state.todayOtaRev,
                },
            ],
        };
        const dataChart2 = {
            labels: labelChart1,
            datasets: [
                {
                    lineTension: 0.5,
                    label: "Room Night",
                    backgroundColor: 'rgb(23, 89, 74)',
                    borderColor: "rgb(23, 89, 74)",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'rgb(23, 89, 74)',
                    pointBackgroundColor: "rgb(23, 89, 74)",
                    data: [roomgcb, roomgcr, roomccp, roomcpl, roomacp, roomahs, roomapl],
                },
            ],
        };
        const revChartGCB = {
            llabels: labelgcb,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepgcb,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };


        const revChartGCR = {
            labels: labelgcr,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepgcr,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };

        const revChartCCP = {
            labels: labelccp,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepccp,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };

        const revChartCPL = {
            labels: labelcpl,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepcpl,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };

        const revChartAHS = {
            labels: labelahs,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepahs,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };

        const revChartAPL = {
            labels: labelapl,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepapl,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };

        const revChartACP = {
            labels: labelacp,
            datasets: [{
                label: 'Revenue',
                data: this.state.keepacp,
                backgroundColor: arrayColor,
                hoverOffset: 4
            }]
        };
        const dataOption1 = {
            plugins: {

                tooltips: {
                    mode: 'index',
                    titleFontSize: 12,
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    backgroundColor: '#fff',
                    titleFontFamily: 'Montserrat',
                    bodyFontFamily: 'Montserrat',
                    cornerRadius: 3,
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": Rp " + Number(
                                tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
                                    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," +
                                        c : c;
                                });
                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        fontFamily: 'Montserrat',
                    },
                },

                title: {
                    display: false,
                    text: 'Normal Legend'
                }
            },
            scales: {
                x: {
                    display: true,


                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Rupiah',
                    },

                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        },
                        y: [{
                            grid: {
                                drawBorder: false,
                                color: 'rgba(235,237,242,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                            }
                        }],
                    }

                }

            },
        }
        const dataOption2 = {
            plugins: {

                tooltips: {
                    mode: 'index',
                    titleFontSize: 12,
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    backgroundColor: '#fff',
                    titleFontFamily: 'Montserrat',
                    bodyFontFamily: 'Montserrat',
                    cornerRadius: 3,
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": Rp " + Number(
                                tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
                                    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," +
                                        c : c;
                                });
                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        fontFamily: 'Montserrat',
                    },
                },

                title: {
                    display: false,
                    text: 'Normal Legend'
                }
            },
            scales: {
                x: {
                    display: true,


                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Pax',
                    },

                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        },
                        y: [{
                            grid: {
                                drawBorder: false,
                                color: 'rgba(235,237,242,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                            }
                        }],
                    }

                }

            },
        }
        return (
            <>

                <div>
                    <div >
                        <div className="row" >
                            <div className="col-lg-12" >
                                <div className="single_element" style={{ padding: "20px" }}>
                                    <div className="quick_activity" >
                                        <div className="row" >
                                            <div className="white_box mb_10 min_430" style={{ padding: "10px" }}>
                                                <div className="box_header">
                                                    <div className="main-title">
                                                        <h3 className="mb-0">OTA Summary Room Night & Revenue Total - {moment(new Date()).format("DD MMMM YYYY")}</h3>
                                                    </div>
                                                </div>
                                                <div className="row " >
                                                    <div className="col-xl-12 col-lg-12">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(28, 93, 220)", marginTop: "20px" }}>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <h3 style={{ color: "white", fontSize: "25px" }}> Room Night Total</h3>
                                                                </div>
                                                                <div className='col-6' >
                                                                    <h3 style={{ float: "right" }}>

                                                                        <NumericFormat style={{ color: "white", fontWeight: "bolder", fontSize: "25px" }} displayType={'text'} value={this.state.roomnight ?? '0'} thousandSeparator={true} />
                                                                    </h3>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div >
                                                    <div className="col-xl-12  col-lg-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(219, 143, 0)", marginTop: "20px" }}>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <h3 style={{ color: "white", fontSize: "25px" }}> Revenue Total</h3>
                                                                </div>
                                                                <div className='col-6' >
                                                                    <h3 style={{ float: "right" }}>
                                                                        <span style={{ color: "white", fontWeight: "bolder", fontSize: "25px" }}>Rp. </span>
                                                                        <NumericFormat style={{ color: "white", fontWeight: "bolder", fontSize: "25px" }} displayType={'text'} value={this.state.revenue ?? '0'} thousandSeparator={true} />
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>

                                                </div >
                                            </div >
                                        </div >

                                    </div >
                                </div >
                            </div >
                        </div>
                        <div className="row" >
                            <div className="col-lg-12" >
                                <div className="single_element" style={{ padding: "20px" }}>
                                    <div className="quick_activity" >
                                        <div className="row" >
                                            <div className="white_box mb_10 min_430" style={{ padding: "10px" }}>
                                                <div className="main-title">
                                                    <h3 className="mb-0">OTA Summary Detail - {moment(new Date()).format("DD MMMM YYYY")}</h3>
                                                </div>
                                                <div className="row d-flex justify-content-center" >
                                                    <div className="col-xl-6 col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(0, 43, 91)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>Grand Cempaka Business Hotel</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revgcb ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomgcb ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                    <div className="col-xl-6  col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(28, 93, 220)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>Grand Cempaka Resort</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revgcr ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomgcr ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>
                                                    <div className="col-xl-6  col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(40, 84, 48)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>
                                                                d'Arcici Hotel Cempaka Putih</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revacp ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomacp ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>
                                                    <div className="col-xl-6  col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(14, 94, 111)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>
                                                                d'Arcici Hotel Sunter</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revahs ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomahs ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>
                                                    <div className="col-xl-6  col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(43, 122, 11)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>
                                                                d'Arcici Hotel Plumpang</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revapl ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomapl ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>
                                                    <div className="col-xl-6  col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(21, 0, 80)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>C'One Hotel Cempaka Putih</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revccp ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomccp ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>
                                                    <div className="col-xl-6  col-12 ">
                                                        <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "rgb(36, 47, 155)", marginTop: "20px" }}>
                                                            <h3 style={{ color: "white", fontSize: "21px" }}>C'One Hotel Pulomas</h3>
                                                            <div className='coveragePool'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Revenue</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>
                                                                        <span>Rp. </span>
                                                                        <NumericFormat displayType={'text'} value={this.state.revcpl ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <h4 style={{ fontSize: "16px" }}>Room Night</h4>
                                                                    </div>
                                                                    <div className='col-6' style={{ color: "white", fontWeight: "bolder", fontSize: "16px" }}>

                                                                        <NumericFormat displayType={'text'} value={this.state.roomcpl ?? '0'} thousandSeparator={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </div>
                                                </div >
                                            </div >
                                        </div >

                                    </div >
                                </div >
                            </div >
                        </div>
                    </div>
                    <div >
                        <div class="single_element" style={{ padding: "20px" }}>

                            <div className="row white_box" style={{ padding: "10px" }}>
                                <div className="col-lg-6 col-xl-6">
                                    <div className=" mb_30">
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <h3 className="mb-0">Today OTA Hotel Revenue Comparison - {moment(new Date()).format("DD MMMM YYYY")}</h3>
                                            </div>
                                        </div>
                                        <Bar
                                            width="100%"
                                            height="70%"
                                            data={dataChart1}
                                            options={dataOption1}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6">
                                    <div className=" mb_30">
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <h3 className="mb-0">Today OTA Hotel Room Night Comparison - {moment(new Date()).format("DD MMMM YYYY")}</h3>
                                            </div>
                                        </div>
                                        <Bar
                                            width="100%"
                                            height="70%"
                                            data={dataChart2}
                                            options={dataOption2}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div >
                        <div class="single_element" style={{ padding: "20px" }}>

                            <div className="row white_box" style={{ padding: "10px" }}>
                                <div className="col-lg-12 col-xl-12">
                                    <div className=" mb_30" >
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <h3 className="mb-0">Today OTA Revenue Comparison - {moment(new Date()).format("DD MMMM YYYY")}</h3>
                                            </div>
                                        </div>
                                        <Bar
                                            width="100%"
                                            height="70%"
                                            data={dataChart3}
                                            options={dataOption1}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div >
                        <div class="single_element" style={{ padding: "20px" }}>

                            <div className="row white_box d-flex justify-content-center" style={{ padding: "10px" }}>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today GCB OTA Growth - {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut

                                            data={revChartGCB}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today GCR OTA Growth - {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut
                                            data={revChartGCR}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today ACP OTA Growth - {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut
                                            data={revChartACP}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today AHS OTA Growth - {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut
                                            data={revChartAHS}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today APL OTA Growth - {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut
                                            data={revChartAPL}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today CCP OTA Growth- {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut
                                            data={revChartCCP}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className=" mb_30" style={{ height: "300px", margin: "10px" }}>
                                        <div className="box_header" >
                                            <div className="main-title">
                                                <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>Today CPL OTA Growth - {moment(new Date()).format("DD MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                        <Doughnut
                                            data={revChartCPL}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        );
    }
}