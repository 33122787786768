import React, { Component, useState } from "react";
import Navbar from "../../layouts/navbar.component";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { Link } from "react-router-dom";
import AuthService from "../../../services/auth.service";

//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
import http from "../../../services/http";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};


export default class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleSubmitUser = this.handleSubmitUser.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
        this.state = {
            data: [],
            unitData: [],
            roles: [],
            active: '',
            idStatus: '',
            hotelShow: '',
            detail: '',

        }
    }

    handleSubmitEdit = event => {
        this.setState({
            message: "",
        });
        const idEditShow = this.idStatus;
        event.preventDefault();

        const config = {
            headers: {
                "Authorization": user.access_token,
            }
        };
        const url = http.getBaseURL() + 'users/' + idEditShow;
        const data = {
            name: this.state.name,
            email: this.state.email,
            username: this.state.username,
            roleId: this.state.roleId,
            hotelId: this.state.hotelId,
            password: this.state.password,
            repassword: this.state.repassword,
            active: this.state.active,
        }
        axios.put(url, data, config)
            .then(res => {
                window.location = "/user-management"
            })
            .catch((error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    message: resMessage
                });
            });
    }
    handleSubmitUser = event => {
        event.preventDefault();
        const data = {
            name: this.state.name,
            email: this.state.email,
            username: this.state.username,
            roleId: this.state.roleId,
            hotelId: this.state.hotelId,
            password: this.state.password,
            repassword: this.state.repassword,
        }
        const config = {
            headers: {
                'Authorization': user.access_token
            }
        };
        const url = http.getBaseURL() + 'users';
        axios.post(url, data, config)
            .then(res => {

                window.location = "/user-management" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
            });
    }

    handleChangeRole = (e) => {
        this.setState({
            hotelShow: e.target.value
        })
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    logIdStatus = (id) => {
        this.idStatus = id;
    };




    deleteRow(id, e) {
        this.idStatus = id
        const idDelete = this.idStatus;
        axios.delete(http.getBaseURL() + 'users/' + idDelete, { headers: authHeader() })
            .then(res => {
                const data = this.state.data.filter(item => item.id !== id);
                this.setState({ data });
            })

    }

    detailRow(id, e) {

        this.idStatus = id;
        const IdStatusNow = this.idStatus;
        axios.get(http.getBaseURL() + 'users/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data });

        });

        axios.get(http.getBaseURL() + 'references/roles').then(res => {
            //Storing users detail in state array object
            this.setState({ roles: res.data.data });

        });
    }

    showRole() {
        axios.get(http.getBaseURL() + 'references/roles').then(res => {
            //Storing users detail in state array object
            this.setState({ roles: res.data.data });

        });
    }

    componentDidMount() {
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
            })
            .catch(function (error) {
            });


        axios.get(http.getBaseURL() + 'users', { headers: authHeader() }).then(res => {
            //Storing users detail in state array object
            this.setState({ data: res.data.data.users });
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {

        return (
            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Manajemen User</span>                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Manajemen User</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner ">
                    <div className="container-fluid plr_30 body_white_bg " style={{ paddingTop: "10px" }}>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="QA_section">
                                    <div className="white_box_tittle list_header mt-3">
                                        <h4>Data User</h4>
                                        <div className="box_right d-flex lms_block">

                                            <div className="add_button ms-2">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#modal-user-register" className="btn_1" onClick={() => this.showRole()} >Add
                                                    New</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="QA_table" style={{ overflow: "scroll" }}>
                                        <table id="example" className="table lms_table_active">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Unit</th>
                                                    <th scope="col">Last Login</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.data.map((result, i) => {
                                                    return (

                                                        <tr key={i}>
                                                            <td>{result.id}</td>
                                                            <td>{result.name}</td>
                                                            <td>{result.Role?.name}</td>
                                                            <td>
                                                                {result.Hotel ? (
                                                                    result.Hotel.name
                                                                ) : (
                                                                    "Kantor Pusat"
                                                                )}
                                                            </td>
                                                            <td>{result.lastLogin}</td>

                                                            <td className="text-center">
                                                                {result.active == true ? (
                                                                    <a href="#" className="status_btn ">Active</a>
                                                                ) : (
                                                                    <a href="#" className="status_btn red_btn">InActive</a>
                                                                )}
                                                            </td>

                                                            <td className="text-center">
                                                                <div>
                                                                    <button style={{ margin: "0 2px" }} className="btn btn-warning btn-sm  d-sm-inline-block" onClick={() => this.detailRow(result.id)} data-bs-toggle="modal" data-bs-target="#modal-user-edit">
                                                                        <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <button style={{ margin: "0 2px" }} className="btn btn-primary btn-sm  d-sm-inline-block" onClick={() => this.detailRow(result.id)} data-bs-toggle="modal" data-bs-target="#modal-user-show">
                                                                        <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <button onClick={(e) => this.deleteRow(result.id, e)} href="#" style={{ margin: "0 10px" }} className="btn btn-danger btn-sm  d-sm-inline-block">
                                                                        <svg style={{ margin: "0 2px" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0m6 0l6 0"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal modal-blur fade" id="modal-user-register" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Buat Pengguna Baru</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmitUser} className="card">
                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama "

                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Pengguna/Username</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="username"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama Pengguna/Username"

                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="Masukan Email"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Select Role</label>
                                                        <select
                                                            className="form-control"
                                                            name="roleId"
                                                            onChange={this.handleChangeRole}
                                                        >
                                                            <option>Pilih Role</option>
                                                            {this.state.roles?.map((result) => {
                                                                return (
                                                                    <option onChange={this.onChangeRoleIdId} value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>

                                                    {this.state.hotelShow == 4 ?

                                                        <div className="mb-3">
                                                            <label className="form-label">Pilih Hotel</label>
                                                            <select
                                                                className="form-control"
                                                                name="hotelId"
                                                                onChange={this.handleChange}

                                                            >
                                                                <option>Pilih Unit</option>
                                                                {this.state.unitData?.map((result) => {
                                                                    return (
                                                                        <option value={result.id}>{result.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        : null

                                                    }

                                                    <div className="mb-3">
                                                        <label className="form-label">Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            placeholder="Masukan Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Re-Type Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="repassword"
                                                            placeholder="Masukan Re-Type Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal modal-blur fade" id="modal-user-show" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.detail?.user?.name}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className="card">
                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.user?.name}
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama "
                                                            disabled

                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Pengguna/Username</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="username"
                                                            value={this.state.detail?.user?.username}
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama Pengguna/Username"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={this.state.detail?.user?.email}
                                                            placeholder="Masukan Email"
                                                            onChange={this.handleChange}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Role</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={this.state.detail?.user?.Role.name}
                                                            placeholder="Masukan Email"
                                                            onChange={this.handleChange}
                                                            disabled
                                                        />
                                                    </div>

                                                    {this.state.detail?.user?.Role.id == 4 ?

                                                        <div className="mb-3">
                                                            <label className="form-label">Admin Hotel</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                value={this.state.detail?.user?.Hotel?.name}
                                                                placeholder="Masukan Email"
                                                                onChange={this.handleChange}
                                                                disabled
                                                            />
                                                        </div>
                                                        : null

                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal modal-blur fade" id="modal-user-edit" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Lakukan Perubahan pada Pengguna</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmitEdit} className="card">
                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            defaultValue={this.state.detail?.user?.name}
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama "

                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Pengguna/Username</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="username"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.detail?.user?.username}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama Pengguna/Username"

                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="Masukan Email"
                                                            defaultValue={this.state.detail?.user?.email}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Select Role</label>
                                                        <select
                                                            className="form-control"
                                                            name="roleId"
                                                            onChange={this.handleChangeRole}
                                                        >
                                                            <option onChange={this.onChangeRoleIdId} value={this.state.detail?.user?.Role?.id}>{this.state.detail?.user?.Role?.name}</option>
                                                            <option>Pilih Role</option>
                                                            {this.state.roles?.map((result) => {
                                                                return (
                                                                    <option onChange={this.onChangeRoleIdId} value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>

                                                    {this.state.hotelShow == 4 || this.state.detail?.user?.Role?.id == 4 ?

                                                        <div className="mb-3">
                                                            <label className="form-label">Pilih Hotel</label>
                                                            <select
                                                                className="form-control"
                                                                name="hotelId"
                                                                onChange={this.handleChange}

                                                            >
                                                                <option onChange={this.onChangeRoleIdId} value={this.state.detail?.user?.Hotel?.id}>{this.state.detail?.user?.Hotel?.name}</option>
                                                                <option>Pilih Unit</option>
                                                                {this.state.unitData?.map((result) => {
                                                                    return (
                                                                        <option value={result.id}>{result.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        : null

                                                    }

                                                    <div className="mb-3">
                                                        <label className="form-label">Status Akun</label>
                                                        <select
                                                            className="form-control"
                                                            name="active"
                                                            onChange={this.handleChange}

                                                        >
                                                            {this.state.detail?.user?.active == true ?

                                                                <option value={1}>Aktif</option>
                                                                : <option value={0}>Non-Aktif</option>

                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            placeholder="Masukan Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Re-Type Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="repassword"
                                                            placeholder="Masukan Re-Type Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}