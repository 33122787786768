import axios from "axios";
import http from "./http";
import authHeader from "./auth-header";
const API_URL = http.getBaseURL() + "auth/";
const user = JSON.parse(localStorage.getItem('user'));
class AuthService {
  login(user, password) {
    return axios
      .post(API_URL + "login", {
        user,
        password
      })
      .then(response => {
        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");

  }
  register(name, username, roleId, password, repassword) {
    return axios.post(http.getBaseURL() + 'users',
      {
        name,
        username,
        roleId,
        password,
        repassword,
      },
      {
        headers: {
          'Authorization': user.accessToken
        }
      }

    );
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
