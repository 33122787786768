import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import { withRouter } from '../common/with-router';
import http from "../services/http";
import Logo from './../assets/jxb-white.png'
import PageParseTitle from "../services/PageParseTitle";
import $ from 'jquery';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.changeTypePassword = this.changeTypePassword.bind(this);

    this.state = {
      user: "",
      password: "",
      loading: false,
      message: "",
      passwordType: true
    };
  }

  onChangeUsername(e) {
    this.setState({
      user: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.user, this.state.password).then(
        () => {
          this.props.router.navigate("/home");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  changeTypePassword() {
    this.setState({ passwordType: !this.state.passwordType });
  }

  componentDidMount() {

    console.log(this.state.passwordType)
  }

  render() {
    const { passwordType } = this.state;

    return (

      <>
        <section className="ftco-section d-flex align-items-center justify-content-center" >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10">
                <div className="wrap d-md-flex">
                  <div className="login-img d-flex align-items-center justify-content-center"
                  >
                    <img className="logo-image-login" src={Logo} />
                  </div>
                  <div className="login-wrap p-4 p-md-5">
                    <div className="d-flex">
                      <div className="w-100">
                        <h1 className="mb-4">Finance Dashboard</h1>
                      </div>


                    </div>

                    <Form
                      className="signin-form"
                      onSubmit={this.handleLogin}
                      ref={c => {
                        this.form = c;
                      }}
                    >
                      <div className="form-group mb-3">
                        <label className="label" for="name">Username</label>
                        <div className="row">
                          <div className="col-12" style={{ paddingRight: "0" }}>
                            <Input
                              type="text"
                              className="form-control"
                              name="user"
                              value={this.state.user}
                              onChange={this.onChangeUsername}
                              validations={[required]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="label" for="password">Password</label>
                        <div className="row">
                          <div className="col-11" style={{ paddingRight: "0" }}>
                            <Input
                              type={this.state.passwordType ? 'password' : 'text'}
                              className="form-control icon-input"
                              style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                              name="password"
                              value={this.state.password}
                              onChange={this.onChangePassword}
                              validations={[required]}
                              id="myInput"
                            />
                          </div>
                          <div className="col-1" style={{ padding: "0" }} >
                            <span>
                              <button type="button" className="btn btn-primary form-control" onClick={(e) => this.changeTypePassword()} style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}>
                                {this.state.passwordType ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-12" style={{ paddingRight: "0" }}>
                            <button type="submit"
                              className="form-control btn btn-primary rounded submit px-3"
                              disabled={this.state.loading}>
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Sign In</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {this.state.message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {this.state.message}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                          this.checkBtn = c;
                        }}
                      />
                    </Form>



                    <p style={{ paddingTop: '20px' }} className="text-center">Not a member <a data-toggle="tab" href="#">Contact Admin</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </>
    );
  }
}

export default withRouter(Login);