import React, { Component } from 'react';
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import { Link } from "react-router-dom";
import http from '../../../services/http';
import Navbar from '../../layouts/navbar.component';
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class UserProfile extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
        this.state = {
            name: '',
            email: '',
            password: '',
            repassword: '',
            profile: [],
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    handleSubmit = event => {
        event.preventDefault();
        const data = {
            name: this.state.name,
        }
        const config = {
            headers: {
                'Authorization': user.access_token
            }
        };
        const url = http.getBaseURL() + "users/change-profile";
        axios.put(url, data, config)
            .then(res => {
                window.location = "/profile    " //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
            });
    }

    handleSubmitPassword = event => {
        event.preventDefault();
        const data = {
            password: this.state.password,
            repassword: this.state.repassword,
        }
        const config = {
            headers: {
                'Authorization': user.access_token
            }
        };
        const url = http.getBaseURL() + "users/change-password";
        axios.put(url, data, config)
            .then(res => {
                window.location = "/profile    " //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
            });
    }

    componentDidMount() {
        axios.get(http.getBaseURL() + 'users/profile', { headers: authHeader() }).then(res => {
            //Storing users detail in state array object
            this.setState({ profile: res.data.data });
        });

    }
    render() {
        const { profile } = this.state;
        return (
            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>My Profile</h2>
                                    </div>
                                </div>

                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner ">

                    <div className="container-fluid plr_30 body_white_bg " style={{ paddingTop: "10px" }}>

                        <div className="row justify-content-center">
                            <div className="col-md-6 col-xl-12">

                                <div className="mb-3">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"

                                        placeholder="Input Name"

                                        value={this.state.profile.name}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Username</label>
                                    <input
                                        type="text"
                                        className="form-control"

                                        placeholder="Input Username"

                                        value={this.state.profile.username}
                                        disabled
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"

                                        placeholder="Input Username"

                                        value={this.state.profile.email}
                                        disabled
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Status Active</label>
                                    <input
                                        type="text"
                                        className="form-control"

                                        placeholder="Input Username"

                                        value={this.state.profile.active}
                                        disabled
                                    />
                                </div>
                            </div>


                        </div>

                        <div>
                            <div className='d-flex'>


                                <a href="#" style={{ margin: "5px" }} class="btn btn-primary btn-sm d-sm-inline-block " data-bs-toggle="modal" data-bs-target="#modal-user-profile">
                                    Change Profile
                                </a>


                                <a href="#" style={{ margin: "5px" }} class="btn btn-info btn-sm  d-sm-inline-block " data-bs-toggle="modal" data-bs-target="#modal-user-password">
                                    Change Password
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="modal modal-blur fade" id="modal-user-profile" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Buat Pengguna Baru</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmit} className="card">
                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                            placeholder="Masukan Nama "

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal modal-blur fade" id="modal-user-password" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Lakukan Pergantian Password</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmitPassword} className="card">
                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            placeholder="Masukan Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Re-Type Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="repassword"
                                                            placeholder="Masukan Re-Type Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
