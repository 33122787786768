import React, { Component } from "react";

import { Link, useNavigate } from "react-router-dom";
// import AuthVerify from "./common/auth-verify";
import EventBus from "../../common/EventBus";
import AuthService from "../../services/auth.service";
import userLogo from "../../assets/user.png";
import authHeader from "../../services/auth-header";
import http from "../../services/http";
import axios from 'axios';
import $ from 'jquery';
import PageParseTitle from "../../services/PageParseTitle";

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            data: [],
            thisTitlePage: '',
            showAdminBoard: false,
            currentUser: undefined,
        };
    }




    componentDidMount() {
        const user = AuthService.getCurrentUser();
        axios.get(http.getBaseURL() + 'auth/check-login', { headers: authHeader() })
            .then(res => {
            })
            .catch(function (error) {
                if (error.response.data.code == 401) {
                    AuthService.logout();
                    window.location.reload();
                    this.setState({
                        showAdminBoard: false,
                        currentUser: undefined,
                    });
                }
            });

        if (user) {
            this.setState({
                currentUser: user,
            });
        }
        EventBus.on("logout", () => {
            this.logOut();
        });
        $(".sidebar_icon").on("click", function () {
            $(".sidebar").toggleClass("active_sidebar");
        });

        $(".sidebar_close_icon").on("click", function () {
            $(".sidebar").removeClass("active_sidebar");
        });
        $(document).click(function (event) {
            if (!$(event.target).closest(".sidebar_icon, .sidebar").length) {
                $("body").find(".sidebar").removeClass("active_sidebar");
            }
        });


    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    logOut() {
        AuthService.logout();
        this.setState({
            showAdminBoard: false,
            currentUser: undefined,
        });
    }

    render() {
        const { showAdminBoard, currentUser } = this.state;

        return (
            <>

                <div className="header_right d-flex justify-content-between align-items-center">
                    <div className="profile_info">
                        <img src={userLogo} alt="User Logo" />
                        <div className="profile_info_iner">
                            <p>Selamat Datang!</p>
                            <h5>{currentUser?.name}</h5>
                            <h5>{currentUser?.role?.name} {currentUser?.hotel?.name}</h5>
                            <div className="profile_info_details">

                                <a href="/profile">Profil <i className="ti-key"></i></a>
                                <a href="/login" onClick={this.logOut}>Log Out <i className="ti-shift-left"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </>




        );
    }
}