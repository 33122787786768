import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from 'sweetalert';
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from 'axios';
import authHeader from "../../../services/auth-header";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import moment from "moment";
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class LogDailyPull extends Component {
    constructor(props) {
        super(props);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            generateHotel: '',
            generateDate: '',
            hotelModalName: '',
            hotelId: '',
            date: '',
            dsrExcel: '',
            dsrPdf: '',
            waitDownload: '',
            unitData: [],
            logGenerateDsr: [],
            showAdminHotel: false,
            currentUser: undefined,
        };
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.setState({
            hotelModalName: e.target[e.target.selectedIndex].text
        })
    }
    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
            hotelId: val
        });
    }

    handleUpdate = (e) => {
        axios.get(http.getBaseURL() + 'reports/logs', { headers: authHeader() })
            .then(res => {
                this.setState({ logGenerateDsr: res.data.data });
            })
            .catch(function (error) {
            });
    }


    handleGenerate = event => {
        const user = AuthService.getCurrentUser();
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.access_token,
            },
        };

        if (user.hotel) {
            this.setState({
                hotelId: user.hotel.id,
                date: this.state.date,
            })
        } else {
            this.setState({
                hotelId: this.state.hotelId,
                date: this.state.date,
            })
        }

        //const url = http.getBaseURL() + 'reports/daily-reports';
        axios.get(http.getBaseURL() + 'reports/daily-reports/' + this.state.hotelId + '/' + this.state.date, config)
            .then(res => {
                this.setState({
                    waitDownload: res.data.message,
                    dsrExcel: res.data.data.excel,
                    dsrPdf: res.data.data.pdf,
                })
            })
            .catch((error) => {
            });
    }


    componentDidMount() {

        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showAdminHotel: user.role.name.includes('Admin Hotel'),
            });
        }
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
            })
            .catch(function (error) {
            });

        axios.get(http.getBaseURL() + 'reports/logs', { headers: authHeader() })
            .then(res => {
                this.setState({ logGenerateDsr: res.data.data });
            })
            .catch(function (error) {
            });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable({
                    aaSorting: []
                });
            }, 1000);
        });
        $(function () {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate() - 1;

            var year = dtToday.getFullYear();

            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            $('#txtDate').attr('max', maxDate);
        });

    }

    render() {
        const { showAdminHotel, currentUser } = this.state;
        return (

            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Generate DSR Report</span>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Log Pull Daily Report Data</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >


            </>
        );
    }
}
