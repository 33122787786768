import React, { Component } from "react";
import Logo from "../../../assets/jxb-black.png";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import http from "../../../services/http";
import moment from "moment";
export default class SlideDAP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const config = {
      headers: {
        "X-APP-KEY": "PTJAKTOURJXBPTJAKTOURJXBPTJAKTOURJXB",
      },
    };
    const url = http.getBaseURL() + "dashboard";
    axios
      .get(url, config)
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch(function (error) {});
  }

  render() {
    const labelChart1 =
      this.state.data?.data?.detail?.apl?.dataGraphic?.lastWeekDays;
    const labelChart2 =
      this.state.data?.data?.detail?.apl?.dataGraphic?.lastWeekYesterdays;
    const dataChart1 = {
      labels: labelChart2,
      datasets: [
        {
          label: "F&B Revenue",
          borderColor: "#2B7A0B",
          backgroundColor: "#2B7A0B",
          hoverBackgroundColor: "#2B7A0B",
          pointRadius: 0,
          fill: false,
          borderWidth: 2,
          data: this.state.data?.data?.detail?.apl?.dataGraphic
            ?.lastWeekFBRevenues,
        },
      ],
    };
    const dataChart2 = {
      labels: labelChart2,
      datasets: [
        {
          label: "Room Revenue",
          borderColor: "#2B7A0B",
          backgroundColor: "#2B7A0B",
          hoverBackgroundColor: "#2B7A0B",
          pointRadius: 0,
          fill: false,
          borderWidth: 2,
          data: this.state.data?.data?.detail?.apl?.dataGraphic
            ?.lastWeekRoomRevenues,
        },
      ],
    };
    const dataChart3 = {
      labels: labelChart1,
      datasets: [
        {
          label: "Weekly Occupancy",
          borderColor: "#2B7A0B",
          backgroundColor: "#2B7A0B",
          hoverBackgroundColor: "#2B7A0B",
          pointRadius: 0,
          fill: false,
          borderWidth: 2,

          data: this.state.data?.data?.detail?.apl?.dataGraphic
            ?.lastWeekOccupancies,
        },
      ],
    };
    const dataOption1 = {
      plugins: {
        responsive: true,
        legend: false,
      },
      scales: {
        x: {
          display: true,

          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: "Rupiah",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              if (parseInt(value) >= 1000) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return value;
              }
            },
            y: [
              {
                grid: {
                  drawBorder: false,
                  color: "rgba(235,237,242,1)",
                  zeroLineColor: "rgba(235,237,242,1)",
                },
              },
            ],
          },
        },
      },
    };

    const dataOption2 = {
      plugins: {
        responsive: true,
        legend: false,
      },
      scales: {
        x: {
          display: true,

          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: "Percent",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              if (parseInt(value) >= 1000) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return value;
              }
            },
            y: [
              {
                grid: {
                  drawBorder: false,
                  color: "rgba(235,237,242,1)",
                  zeroLineColor: "rgba(235,237,242,1)",
                },
              },
            ],
          },
        },
      },
    };

    return (
      <>
        {this.state.data.data?.detail?.apl ? (
          <div>
            <div className="row">
              <div className="col-lg-12">
                <div className="single_element" style={{ padding: "20px" }}>
                  <div className="quick_activity">
                    <div className="row">
                      <div
                        className="white_box mb_10 min_430"
                        style={{ padding: "10px" }}
                      >
                        <div className="box_header  box_header_block">
                          <div className="main-title">
                            <h3 className="mb-0">Dashboard Summary Data</h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 ">
                            <div
                              className="single_quick_activity coverage"
                              style={{
                                background: "#2B7A0B",
                                marginTop: "20px",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                  <h4>
                                    Total Revenue F&B -{" "}
                                    {moment(
                                      this.state.data.data.date?.yesterday
                                    ).format("MMMM Do YYYY")}
                                  </h4>
                                  <h3>
                                    Rp <span> </span>
                                    <span
                                      className=""
                                      style={{
                                        color: "white",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {this.state.data ? (
                                        <NumericFormat
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                          displayType={"text"}
                                          value={
                                            this.state.data?.data?.detail?.apl
                                              ?.FBRevenue
                                          }
                                          thousandSeparator={true}
                                        />
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </span>
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                  <h4>
                                    Total Revenue Room -{" "}
                                    {moment(
                                      this.state.data.data.date?.yesterday
                                    ).format("MMMM Do YYYY")}
                                  </h4>
                                  <h3>
                                    Rp <span> </span>
                                    <span
                                      className=""
                                      style={{
                                        color: "white",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {this.state.data ? (
                                        <NumericFormat
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                          displayType={"text"}
                                          value={
                                            this.state.data?.data?.detail?.apl
                                              ?.roomRevenue
                                          }
                                          thousandSeparator={true}
                                        />
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </span>
                                  </h3>
                                  <p style={{ fontSize: "16px" }}>
                                    Average: Rp <span> </span>
                                    <span>
                                      {this.state.data ? (
                                        <NumericFormat
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                          displayType={"text"}
                                          value={
                                            this.state.data?.data?.detail?.apl
                                              ?.yesterdayAvgRoomRevenue
                                          }
                                          thousandSeparator={true}
                                        />
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div
                              className="single_quick_activity coverage"
                              style={{
                                background: "#2B7A0B",
                                marginTop: "20px",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                  <h4 style={{ fontSize: "20px" }}>
                                    Summary Occupancy{" "}
                                  </h4>
                                  <div className="row">
                                    <div className="col-7">
                                      <p
                                        className="title-house"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Realtime
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOccupancy?.realtime
                                          }{" "}
                                          %
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p
                                        className="title-house"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Daily
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOccupancy?.daily
                                          }{" "}
                                          %
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p
                                        className="title-house"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Daily Effective
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOccupancy?.dailyEffective
                                          }{" "}
                                          %
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p
                                        className="title-house"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Weakly
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOccupancy?.weekly
                                          }{" "}
                                          %
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p
                                        className="title-house"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Weakly Effective
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOccupancy
                                              ?.weeklyEffective
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 ">
                            <div
                              className="single_quick_activity coverage-house"
                              style={{
                                background: "#2B7A0B",
                                marginTop: "20px",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-5 col-sm-12">
                                  <h4>Total Occupancy</h4>
                                  <h3>
                                    <i
                                      className="fa fa-hotel"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    <span
                                      className=""
                                      style={{
                                        color: "white",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      <span
                                        className=""
                                        style={{
                                          color: "white",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        {this.state.data ? (
                                          <span
                                            style={{
                                              color: "white",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {
                                              this.state.data?.data?.detail?.apl
                                                ?.occupancy
                                            }
                                            <span
                                              style={{
                                                color: "white",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              {" "}
                                              %
                                            </span>
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              color: "white",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {" "}
                                            No data yet
                                          </span>
                                        )}
                                      </span>
                                    </span>
                                  </h3>
                                  <p>
                                    Average: Rp <span> </span>
                                    <span>
                                      {this.state.data ? (
                                        <NumericFormat
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                          displayType={"text"}
                                          value={
                                            this.state.data?.data?.detail?.apl
                                              ?.avgRoomRevenue
                                          }
                                          thousandSeparator={true}
                                        />
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-7 col-sm-12">
                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">Total Rooms</p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.TotalRooms
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">
                                        Occupied Rooms
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.OccupiedRooms
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">
                                        Closed Rooms
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.VcantClosedRooms
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">
                                        Available Rooms
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.AvailRooms
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">
                                        Expected Arrivals
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.DueInBookings
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">
                                        Expected Departures
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.ExpDepartures
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7">
                                      <p className="title-house">
                                        Rooms To Sell
                                      </p>
                                    </div>
                                    <div className="col-5">
                                      {this.state.data ? (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {
                                            this.state.data?.data?.detail?.apl
                                              ?.summaryOfHouse.RoomsToSell
                                          }
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {" "}
                                          No data yet
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-xl-4">
                <div className="white_box mb_30 min_430">
                  <div className="box_header  box_header_block">
                    <div className="main-title">
                      <h3 className="mb-0">Weekly F&B Revenue</h3>
                    </div>
                  </div>
                  <Bar
                    height="50%"
                    width="100%"
                    data={dataChart1}
                    options={dataOption1}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-xl-4">
                <div className="white_box mb_30 min_430">
                  <div className="box_header  box_header_block">
                    <div className="main-title">
                      <h3 className="mb-0">Weekly Room Revenue</h3>
                    </div>
                  </div>
                  <Bar
                    height="50%"
                    width="100%"
                    data={dataChart2}
                    options={dataOption1}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-xl-4">
                <div className="white_box mb_30 min_430">
                  <div className="box_header  box_header_block">
                    <div className="main-title">
                      <h3 className="mb-0">Weekly Occupancy</h3>
                    </div>
                  </div>
                  <Bar data={dataChart3} options={dataOption2} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="single_element" style={{ padding: "20px" }}>
                <div className="quick_activity">
                  <div className="row">
                    <div
                      className="white_box mb_10 min_430"
                      style={{ padding: "0px" }}
                    >
                      <div className="row">
                        <div id="loading">
                          <div id="loading-center">
                            <div id="loading-center-absolute">
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_one"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_two"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_three"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_four"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_five"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_six"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_seven"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_eight"
                              ></div>
                              <div
                                class="object"
                                style={{ backgroundColor: "#2B7A0B" }}
                                id="object_nine"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
