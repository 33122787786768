import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from "sweetalert";
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NumericFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import { downloadExcel } from "react-export-table-to-excel";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
const user = JSON.parse(localStorage.getItem("user"));
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const rupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number);
};

const columns = [
  { header: "Segmentation", key: "segmentation" },
  { header: "Percentage (%)", key: "percentage" },
  { header: "Revenue", key: "revenue" },
];

const workSheetName = "Worksheet-1";
const workBookName = "MyWorkBook";
const myInputId = "myInput";
const workbook = new Excel.Workbook();

export default class SegmentationReport extends Component {
  constructor(props) {
    super(props);
    this.handleYearStart = this.handleYearStart.bind(this);
    this.handleYearEnd = this.handleYearEnd.bind(this);
    this.handleGenerate = this.handleGenerate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);

    this.state = {
      filtervIew: "",
      generateHotel: "",
      generateDate: "",
      hotelModalName: "",
      hotelId: "",
      date: "",
      dsrExcel: "",
      dsrPdf: "",
      waitDownload: "",
      emptyData: "",
      unitData: [],
      segmentationReports: "",
      showAdminHotel: false,
      currentUser: undefined,
      tableToogler: false,
      startYear: "",
      endYear: "",
    };
  }

  handleChangeFilter = (e) => {
    this.setState({ tableToogler: false });

    this.setState({
      filtervIew: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleYearStart = (date) => {
    const dateyear = moment(date).format("YYYY").toLocaleString();
    this.setState({
      startDate: dateyear,
      startYear: date,
    });
    console.log(this.state.startDate);
  };
  handleYearEnd = (date) => {
    const dateyear = moment(date).format("YYYY").toLocaleString();
    this.setState({
      endDate: dateyear,
      endYear: date,
    });
    console.log(this.state.endDate);
  };
  updateInputValue(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      hotelId: val,
    });
  }

  handleGenerate = (event) => {
    this.setState({
      segmentationReports: "",
      tableToogler: true,
      emptyData: "",
      waitDownload: "",
    });

    const user = AuthService.getCurrentUser();
    event.preventDefault();
    const config = {
      headers: {
        Authorization: user.access_token,
      },
      params: {
        filter: this.state.filter,
        end: this.state.endDate,
        start: this.state.startDate,
        hotelId: this.state.hotelId,
      },
    };

    //const url = http.getBaseURL() + 'reports/daily-reports';
    axios
      .get(http.getBaseURL() + "reports/segmentations", config)
      .then((res) => {
        this.setState({ segmentationReports: res.data });
        console.log(res.data);
      })
      .catch((error) => {
        this.setState({ emptyData: error.response.data.code });
      });
  };

  exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Customer Segmentation Reports";
    const headers = [["Segmentation", "Percentage", "Revenue"]];

    const data = [
      [
        "Walk In",
        this.state.segmentationReports.data.WalkIn.percentage + " %",
        rupiah(this.state.segmentationReports.data.WalkIn.revenue),
      ],
      [
        "Association",
        this.state.segmentationReports.data.Association.percentage + " %",
        rupiah(this.state.segmentationReports.data.Association.revenue),
      ],
      [
        "Corporate",
        this.state.segmentationReports.data.Corporate.percentage + " %",
        rupiah(this.state.segmentationReports.data.Corporate.revenue),
      ],
      [
        "Government",
        this.state.segmentationReports.data.Government.percentage + " %",
        rupiah(this.state.segmentationReports.data.Government.revenue),
      ],
      [
        "Online Travel Agent",
        this.state.segmentationReports.data.OnlineTravelAgent.percentage + " %",
        rupiah(this.state.segmentationReports.data.OnlineTravelAgent.revenue),
      ],
      [
        "Offline Travel Agent",
        this.state.segmentationReports.data.OfflineTravelAgent.percentage +
          " %",
        rupiah(this.state.segmentationReports.data.OfflineTravelAgent.revenue),
      ],
      [
        "All Segment",
        this.state.segmentationReports.data.TotalRevenue.percentage + " %",
        rupiah(this.state.segmentationReports.data.TotalRevenue.revenue),
      ],
    ];

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Customer Segmentation Reports.pdf");
  };

  exportExcelv3 = async () => {
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = "Customer Segmentation Reports" + " " || workBookName;
      const data = [
        {
          segmentation: "Walk In",
          percentage: this.state.segmentationReports.data.WalkIn.percentage,
          revenue: this.state.segmentationReports.data.WalkIn.revenue,
        },
        {
          segmentation: "Association",
          percentage:
            this.state.segmentationReports.data.Association.percentage,
          revenue: this.state.segmentationReports.data.Association.revenue,
        },
        {
          segmentation: "Corporate",
          percentage: this.state.segmentationReports.data.Corporate.percentage,
          revenue: this.state.segmentationReports.data.Corporate.revenue,
        },
        {
          segmentation: "Government",
          percentage: this.state.segmentationReports.data.Government.percentage,
          revenue: this.state.segmentationReports.data.Government.revenue,
        },
        {
          segmentation: "Online Travel Agent",
          percentage:
            this.state.segmentationReports.data.OnlineTravelAgent.percentage,
          revenue:
            this.state.segmentationReports.data.OnlineTravelAgent.revenue,
        },
        {
          segmentation: "Offline Travel Agent",
          percentage:
            this.state.segmentationReports.data.OfflineTravelAgent.percentage,
          revenue:
            this.state.segmentationReports.data.OfflineTravelAgent.revenue,
        },
        {
          segmentation: "All Segment",
          percentage:
            this.state.segmentationReports.data.TotalRevenue.percentage,
          revenue: this.state.segmentationReports.data.TotalRevenue.revenue,
        },
      ];

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // merge a range of cells

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 10;
        column.alignment = { horizontal: "center" };
      });

      // loop through data and add each one to worksheet
      data.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      worksheet.spliceRows(1, 0, ["Customer Segmentation Reports"]);
      worksheet.mergeCells("A1:C1");
      // updated the font for first row.
      worksheet.getRow(2).font = {
        name: "Arial",
        color: { argb: "00000000" },
        family: 2,
        size: 12,
        bold: true,
      };

      worksheet.getCell("A1").font = {
        name: "Arial Black",
        color: { argb: "00000000" },
        family: 2,
        size: 14,
        bold: true,
      };

      worksheet.getCell("A1").fill = {
        type: "pattern",
        pattern: "darkTrellis",
        fgColor: { argb: "ffd9d5d4" },
        bgColor: { argb: "FFD9D5D4" },
      };

      // Set Column 3 to Currency Format

      worksheet.getColumn(3).numFmt = '"Rp "#,##0.00;[Red]-"Rp "#,##0.00';

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminHotel: user.role.name.includes("Admin Hotel"),
      });
    }
    const url = http.getBaseURL() + "references/hotels";
    axios
      .get(url)
      .then((res) => {
        this.setState({ unitData: res.data.data });
      })
      .catch(function (error) {});

    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable({
          aaSorting: [],
        });
      }, 1000);
    });
  }

  render() {
    const { showAdminHotel, currentUser } = this.state;
    const fontTd = {
      color: "black",
    };
    return (
      <>
        <div className="container-fluid g-0">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="header_iner d-flex justify-content-between align-items-center">
                <div className="sidebar_icon d-lg-none">
                  <i className="ti-menu"></i>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      margin: "0 10px",
                    }}
                  >
                    Customer Segmentation Reports
                  </span>
                </div>
                <div className="serach_field-area">
                  <div className="search_inner">
                    <h2> Customer Segmentation Reports</h2>
                  </div>
                </div>
                <Navbar />
              </div>
            </div>
          </div>
        </div>

        <div className="main_content_iner">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <div className="row">
              <div className="col-12">
                <div class="card-info" style={{ padding: "0" }}>
                  <div class="card-body">
                    <h5 class="card-title" style={{ fontWeight: "bold" }}>
                      Information
                    </h5>

                    <p class="card-text" style={{ color: "black" }}>
                      Digunakan untuk melakukan menghasilkan data segmentasi
                      masing - masing Hotel
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={this.handleGenerate}>
              <div className="row">
                <div className="col-6" style={{ padding: "15px" }}>
                  <div className="form-page mb-3">
                    <label for="exampleInputPassword1" className="form-label">
                      Choose Unit
                    </label>
                    <select
                      className="form-control"
                      name="hotelId"
                      onChange={this.handleChange}
                    >
                      <option value="">Silakan Pilih</option>
                      <option value="">Semua Hotel</option>
                      {this.state.unitData?.map((result) => {
                        return (
                          <option value={result.id} name={result.name}>
                            {result.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-6" style={{ padding: "15px" }}>
                  <div className="form-page mb-3">
                    <label for="exampleInputPassword1" className="form-label">
                      Choose Filter
                    </label>
                    <select
                      className="form-control"
                      name="filter"
                      onChange={this.handleChangeFilter}
                    >
                      <option value="">Silakan Pilih</option>
                      <option value="today" name="today">
                        Today
                      </option>
                      <option value="daterange" name="daterange">
                        Daterange
                      </option>
                      <option value="month" name="month">
                        Month
                      </option>
                      <option value="year" name="year">
                        Year
                      </option>
                    </select>
                  </div>
                </div>

                {this.state.filtervIew == "daterange" ? (
                  <>
                    <div className="col-4" style={{ padding: "15px" }}>
                      <div className="form-page mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="startDate"
                          placeholder="Generate Date"
                          onChange={this.handleChange}
                          validations={[required]}
                        />
                      </div>
                    </div>
                    <div className="col-4" style={{ padding: "15px" }}>
                      <div className="form-page mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          placeholder="Generate Date"
                          onChange={this.handleChange}
                          validations={[required]}
                        />
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        padding: "45px 15px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                      >
                        Generate
                      </button>
                    </div>
                  </>
                ) : this.state.filtervIew == "month" ? (
                  <>
                    <div className="col-4" style={{ padding: "15px" }}>
                      <div className="form-page mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          Start Month
                        </label>
                        <input
                          type="year"
                          className="form-control"
                          name="startDate"
                          placeholder="Generate Date"
                          onChange={this.handleChange}
                          validations={[required]}
                        />
                      </div>
                    </div>
                    <div className="col-4" style={{ padding: "15px" }}>
                      <div className="form-page mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          End Month
                        </label>
                        <input
                          type="month"
                          className="form-control"
                          name="endDate"
                          placeholder="Generate Date"
                          onChange={this.handleChange}
                          validations={[required]}
                        />
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        padding: "45px 15px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                      >
                        Generate
                      </button>
                    </div>
                  </>
                ) : this.state.filtervIew == "year" ? (
                  <>
                    <div className="col-4" style={{ padding: "15px" }}>
                      <div className="form-page mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          Start Year
                        </label>

                        <DatePicker
                          selected={this.state.startYear}
                          placeholderText="Tahun Mulai"
                          onChange={this.handleYearStart}
                          name="startDate"
                          dateFormat="yyyy"
                          showYearPicker
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-4" style={{ padding: "15px" }}>
                      <div className="form-page mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          End Year
                        </label>
                        <DatePicker
                          selected={this.state.endYear}
                          placeholderText="Tahun Akhir"
                          onChange={this.handleYearEnd}
                          name="endDate"
                          dateFormat="yyyy"
                          showYearPicker
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        padding: "45px 15px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                      >
                        Generate
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-12" style={{ marginBottom: "15px" }}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: "100%" }}
                    >
                      Generate
                    </button>
                  </div>
                )}
              </div>
            </form>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="QA_section">
                  {this.state.tableToogler == true &&
                  this.state.segmentationReports.data ? (
                    <>
                      <div className="white_box_tittle list_header">
                        <h4>Data Output</h4>
                        <div className="box_right d-flex lms_block"></div>
                      </div>
                      <button
                        className="btn btn-primary"
                        style={{ margin: "0 10px" }}
                        onClick={() => this.exportPDF()}
                      >
                        Generate PDF
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ margin: "0 10px" }}
                        onClick={() => this.exportExcelv3()}
                      >
                        Generate Excel
                      </button>
                      <div className="" style={{ overflow: "scroll" }}>
                        <table id="" className="table responsive">
                          <thead>
                            <tr>
                              <th scope="col">Segmentation</th>
                              <th scope="col">Percentage</th>
                              <th scope="col">Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={fontTd}>Walk In</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data.WalkIn
                                    .percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data.WalkIn
                                      .revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={fontTd}>Association</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data
                                    .Association.percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data
                                      .Association.revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={fontTd}>Corporate</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data.Corporate
                                    .percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data
                                      .Corporate.revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={fontTd}>Government</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data.Government
                                    .percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data
                                      .Government.revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={fontTd}>Online Travel Agent</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data
                                    .OnlineTravelAgent.percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data
                                      .OnlineTravelAgent.revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={fontTd}>Offline Travel Agent</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data
                                    .OfflineTravelAgent.percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data
                                      .OfflineTravelAgent.revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={fontTd}>All Segment</td>

                              <td style={fontTd}>
                                {
                                  this.state.segmentationReports.data
                                    .TotalRevenue.percentage
                                }{" "}
                                %
                              </td>
                              <td style={fontTd}>
                                <NumericFormat
                                  value={
                                    this.state.segmentationReports.data
                                      .TotalRevenue.revenue
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                  {this.state.emptyData == 404 && (
                    <>
                      <div className="col-12">
                        <h4 className="text-center mb-5">
                          Data Tidak Dapat Ditemukan
                        </h4>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
