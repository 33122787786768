import React, { Component } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";
// import AuthVerify from "./common/auth-verify";
import EventBus from "../../common/EventBus";
import AuthService from "../../services/auth.service";
import Logo from "../../assets/jxb-black.png";
import LogoDashboard from "../../assets/menu-icon/1.svg";
import LogoDSR from "../../assets/menu-icon/7.svg";
import LogoLogs from "../../assets/menu-icon/2.svg";
import LogoUserManajemen from "../../assets/menu-icon/6.svg";
import LogoUpdate from "../../assets/menu-icon/8.svg";
import authHeader from "../../services/auth-header";
import axios from "axios";
import $ from "jquery";
import PageParseTitle from "../../services/PageParseTitle";
import MetisMenu from "@metismenu/react";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],

      showAdminBoard: false,
      showDireksiBoard: false,
      showFinanceBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        showAdminBoard: user.role.name.includes("Administrator"),
        showDireksiBoard: user.role.name.includes("Direksi"),
        showFinanceBoard: user.role.name.includes("Finance Pusat"),
      });
    }
  }

  render() {
    const { showAdminBoard, showDireksiBoard, showFinanceBoard } = this.state;

    return (
      <>
        <nav className="sidebar" style={{ paddingRight: "10px" }}>
          <div className="logo d-flex justify-content-center">
            <a href="index.html">
              <img src={Logo} alt="" height="100px" />
            </a>
            <div className="sidebar_close_icon d-lg-none">
              <i className="ti-close"></i>
            </div>
          </div>

          <MetisMenu className="sidebar_menu">
            <li>
              <NavLink to="/home" exact>
                <img src={LogoDashboard} alt="Logo Dashboard" />
                Dashboard
              </NavLink>
            </li>
            {showAdminBoard && (
              <li>
                <NavLink to={"/user-management"}>
                  <img src={LogoUserManajemen} alt="Logo User Manajemen" />
                  Manage Users
                </NavLink>
              </li>
            )}

            {/* <MetisMenu className="sidebar_menu">
                                <li>
                                    <Link to="#" >
                                        <img src={LogoLogs} alt="Logo Logs" />
                                        <span>Logs</span>
                                    </Link>
                                    <ul>
                                        <li><NavLink to="/log-combined" style={{ whiteSpace: "normal" }} >Combined</NavLink></li>
                                        <li><NavLink to="#" style={{ whiteSpace: "normal" }} >Penarikan  Daily </NavLink></li>
                                        <li><NavLink to="#" style={{ whiteSpace: "normal" }} >Perhitungan  Daily </NavLink></li>
                                        <li><NavLink to="#" style={{ whiteSpace: "normal" }} >Penarikan  Monthly </NavLink></li>
                                        <li><NavLink to="#" style={{ whiteSpace: "normal" }} >Perhitungan  Monthly </NavLink></li>

                                    </ul>
                                </li>
                            </MetisMenu> */}

            {showAdminBoard && (
              <MetisMenu className="sidebar_menu">
                <li>
                  <Link to="#">
                    <img src={LogoUpdate} alt="" />
                    <span>Update Data</span>
                  </Link>
                  <ul>
                    <li>
                      <NavLink
                        to="/update-daily-server-date"
                        style={{ whiteSpace: "normal" }}
                      >
                        Update Daily Server Date
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/update-data-reset-daily"
                        style={{ whiteSpace: "normal" }}
                      >
                        Reset Daily Data
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/update-data-get-monthly"
                        style={{ whiteSpace: "normal" }}
                      >
                        Get Monthly Data Hotels
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/update-data-reset-monthly"
                        style={{ whiteSpace: "normal" }}
                      >
                        Reset Monthly Data
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </MetisMenu>
            )}

            <li>
              <Link to="#">
                <img src={LogoDSR} alt="Logo DSR" />
                <span>Generate Reports</span>
              </Link>
              <ul>
                <li>
                  <NavLink to="/dsr-generate" style={{ whiteSpace: "normal" }}>
                    Daily Reports
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/msr-generate" style={{ whiteSpace: "normal" }}>
                    Kertas Kerja Bulanan{" "}
                  </NavLink>
                </li>
                {showAdminBoard && (
                  <li>
                    <NavLink
                      to="/summary-monthly-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      Summary Monthly Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showDireksiBoard && (
                  <li>
                    <NavLink
                      to="/summary-monthly-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      Summary Monthly Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showFinanceBoard && (
                  <li>
                    <NavLink
                      to="/summary-monthly-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      Summary Monthly Reports{" "}
                    </NavLink>
                  </li>
                )}

                {showAdminBoard && (
                  <li>
                    <NavLink
                      to="/ota-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      OTA Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showDireksiBoard && (
                  <li>
                    <NavLink
                      to="/ota-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      OTA Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showFinanceBoard && (
                  <li>
                    <NavLink
                      to="/ota-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      OTA Reports{" "}
                    </NavLink>
                  </li>
                )}

                {showAdminBoard && (
                  <li>
                    <NavLink
                      to="/eorder-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      E-Order Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showDireksiBoard && (
                  <li>
                    <NavLink
                      to="/eorder-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      E-Order Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showFinanceBoard && (
                  <li>
                    <NavLink
                      to="/eorder-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      E-Order Reports{" "}
                    </NavLink>
                  </li>
                )}

                {showAdminBoard && (
                  <li>
                    <NavLink
                      to="/segmentation-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      Segmentation Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showDireksiBoard && (
                  <li>
                    <NavLink
                      to="/segmentation-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      Segmentation Reports{" "}
                    </NavLink>
                  </li>
                )}
                {showFinanceBoard && (
                  <li>
                    <NavLink
                      to="/segmentation-report-generate"
                      style={{ whiteSpace: "normal" }}
                    >
                      Segmentation Reports{" "}
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
          </MetisMenu>
        </nav>
      </>
    );
  }
}
