import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from 'sweetalert';
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NumericFormat } from 'react-number-format';
import { downloadExcel } from "react-export-table-to-excel";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import moment from "moment";
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
    }).format(number);
}
export default class SMRDailyGenerate extends Component {
    constructor(props) {
        super(props);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);


        this.state = {
            filtervIew: '',
            filter: 'mtd',
            generateHotel: '',
            generateDate: '',
            hotelModalName: '',
            hotelId: '',
            date: '',
            dsrExcel: '',
            dsrPdf: '',
            waitDownload: '',
            unitData: [],
            summaryData: [],
            showAdminHotel: false,
            currentUser: undefined,
            tableToogler: false
        };
    }

    handleChangeFilter = (e) => {
        this.setState({ tableToogler: false });


        this.setState({
            filtervIew: e.target.value
        });
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log(this.state.filtervIew);
    }
    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
        this.setState({
            hotelModalName: e.target[e.target.selectedIndex].text
        })
    }
    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
            hotelId: val
        });

    }

    handleGenerate = event => {
        this.setState({ summaryData: [] });
        this.setState({ tableToogler: true });
        this.setState({
            waitDownload: '',
        })
        const user = AuthService.getCurrentUser();
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.access_token,
            },
            params: {
                filter: this.state.filter,
                endDate: this.state.endDate,
                startDate: this.state.startDate,
            }
        };

        //const url = http.getBaseURL() + 'reports/daily-reports';
        axios.get(http.getBaseURL() + 'daily/summary/', config)
            .then(res => {
                this.setState({ summaryData: res.data });
                console.log(res.data)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    exportPDF = (e) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Summary Daily Report";
        const headers = [["UNIT CODE", "TOTAL REVENUE", "ROOM REVENUE", "ROOM COST", "F&B REVENUE", "FOOD REVENUE", "FOOD COST", "BEVERAGE REVENUE", "BEVERAGE COST"]];

        const data = this.state.summaryData.data.map(elt => [elt.hotelCode,
        rupiah(elt.totalRevenue),
        rupiah(elt.roomRevenue),
        elt.roomCost,
        rupiah(elt.FBRevenue),
        rupiah(elt.foodRevenue),
        elt.foodCost,
        rupiah(elt.beverageRevenue),
        elt.beverageCost]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Summary Daily Report.pdf")
    }

    exportExcel = (e) => {
        const header = ["UNIT CODE", "TOTAL REVENUE", "ROOM REVENUE", "ROOM COST", "F&B REVENUE", "FOOD REVENUE", "FOOD COST", "BEVERAGE REVENUE", "BEVERAGE COST"];
        const body = this.state.summaryData.data.map(elt => [
            elt.hotelCode,
            rupiah(elt.totalRevenue),
            rupiah(elt.roomRevenue),
            elt.roomCost,
            rupiah(elt.FBRevenue),
            rupiah(elt.foodRevenue),
            elt.foodCost,
            rupiah(elt.beverageRevenue),
            elt.beverageCost]);
        downloadExcel({
            fileName: "Summary Daily Report Spreadsheet",
            sheet: "Sheet 1",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }

    componentDidMount() {

        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showAdminHotel: user.role.name.includes('Admin Hotel'),
            });
        }
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
            })
            .catch(function (error) {
            });


        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable({
                    aaSorting: []
                });
            }, 1000);
        });


    }

    render() {
        const { showAdminHotel, currentUser } = this.state;
        const fontTd = {
            color: "black",

        };
        return (

            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Generate Summary Daily Report</span>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Generate Summary Daily Report</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner">
                    <div className="container-fluid plr_30 body_white_bg pt_30">

                        <form onSubmit={this.handleGenerate} >
                            <div className="row">
                                <div className="col-12" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label">Choose Filter</label>
                                        <select
                                            className="form-control"
                                            name="filter"
                                            onChange={this.handleChangeFilter}
                                        >
                                            <option value="mtd" name="mtd">Month To date</option>
                                            <option value="today" name="today">Today</option>
                                            <option value="ytd" name="ytd">Year to Date</option>
                                            <option value="daterange" name="daterange">Daterange</option>
                                        </select>
                                    </div>


                                </div>

                                {this.state.filtervIew == 'daterange' ?
                                    <>
                                        <div className="col-4" style={{ padding: "15px" }}>
                                            <div className="form-page mb-3">
                                                <label for="exampleInputPassword1" className="form-label">Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="startDate"
                                                    placeholder="Generate Date"
                                                    onChange={this.handleChange}
                                                    validations={[required]}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4" style={{ padding: "15px" }}>

                                            <div className="form-page mb-3">
                                                <label for="exampleInputPassword1" className="form-label">End Date Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="endDate"
                                                    placeholder="Generate Date"
                                                    onChange={this.handleChange}
                                                    validations={[required]}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4" style={{
                                            padding: "45px 15px"
                                        }}>
                                            <button type="submit" className="btn btn-primary" style={{ width: "100%" }} >Generate</button>
                                        </div>
                                    </>
                                    :
                                    <div className="col-12" style={{ marginBottom: "15px" }}>
                                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} >Generate</button>
                                    </div>

                                }


                            </div>
                        </form>
                        <div className="row justify-content-center">
                            <div className="col-12">

                                <div className="QA_section">
                                    <div className="white_box_tittle list_header">
                                        <h4>Data Output</h4>
                                        <div className="box_right d-flex lms_block">
                                        </div>
                                    </div>


                                    {this.state.tableToogler == true && this.state.summaryData.data ?

                                        <>
                                            <button className="btn btn-primary" style={{ margin: "0 10px" }} onClick={() => this.exportPDF()}>Generate PDF</button>
                                            <button className="btn btn-primary" style={{ margin: "0 10px" }} onClick={() => this.exportExcel()}>Generate Excel</button>
                                            <div className="" style={{ overflow: "scroll" }}>
                                                <table id="" className="table responsive" >
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Unit Code</th>
                                                            <th scope="col">Total Revenue</th>
                                                            <th scope="col">Room Revenue</th>
                                                            <th scope="col">Room Cost</th>
                                                            <th scope="col">F&B Revenue</th>
                                                            <th scope="col">Food Revenue</th>
                                                            <th scope="col">Food Cost</th>
                                                            <th scope="col">Beverage Revenue</th>
                                                            <th scope="col">Beverage Cost</th>
                                                            <th scope="col">ARR</th>
                                                            <th scope="col">Occupancy</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.summaryData.data.map((result, i) => {
                                                            return (
                                                                <tr >
                                                                    <td key={i} style={fontTd}>{result.hotelCode}</td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.totalRevenue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.roomRevenue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.roomCost}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.FBRevenue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.foodRevenue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.foodCost}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.beverageRevenue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.beverageCost}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.arr} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />

                                                                    </td>

                                                                    <td style={fontTd}>
                                                                        {result.occupancy}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>


                                                </table>
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
