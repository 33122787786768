class http {
  getBaseURL() {
    let url;
    switch (process.env.NODE_ENV) {
      case "production":
        url = "https://finance-api.jxboard.id/";
        break;
      case "development":
      default:
        url = "https://finance-api.jxboard.id/";
    }
    return url;
  }
}

export default new http();
