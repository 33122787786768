import React, { Component } from 'react';
import Logo from '../../../assets/jxb-black.png';
import { NumericFormat } from 'react-number-format'; import axios from 'axios';
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import http from '../../../services/http';
import moment from "moment";
export default class SlideEORDER extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        const config = {
            headers: {
                'x-app-key': 'PTJAKTOURJXBPTJAKTOURJXBPTJAKTOURJXB'

            }
        };
        const url = http.getBaseURL() + 'daily/e-order';
        var valueNow = [];
        var valuePaid = [];
        var valueUnpaid = [];
        axios.get(url, config)
            .then(res => {
                this.setState({ data: res.data.data });
                console.log(res.data.data);

                valueNow = res.data.data.chartData.map((result, i) => {
                    return (
                        result.date
                    )
                });
                valuePaid = res.data.data.chartData.map((result, i) => {
                    return (
                        result.paid
                    )
                });

                valueUnpaid = res.data.data.chartData.map((result, i) => {
                    return (
                        result.unpaid
                    )
                });
                this.setState({
                    dateLabel: valueNow,
                    dataChart1: valuePaid,
                    dataChart2: valueUnpaid,
                    paidOrder: res.data.data.paid[0].orderCount,
                    paidTotal: res.data.data.paid[0].total,
                    unpaidOrder: res.data.data.unpaid[0].orderCount,
                    unpaidTotal: res.data.data.unpaid[0].total,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        this.interval = setInterval(() => {
            axios.get(url, config)
                .then(res => {
                    this.setState({ data: res.data.data });
                    console.log(res.data.data);

                    valueNow = res.data.data.chartData.map((result, i) => {
                        return (
                            result.date
                        )
                    });
                    valuePaid = res.data.data.chartData.map((result, i) => {
                        return (
                            result.paid
                        )
                    });

                    valueUnpaid = res.data.data.chartData.map((result, i) => {
                        return (
                            result.unpaid
                        )
                    });
                    this.setState({
                        dateLabel: valueNow,
                        dataChart1: valuePaid,
                        dataChart2: valueUnpaid,
                        paidOrder: res.data.data.paid[0].orderCount,
                        paidTotal: res.data.data.paid[0].total,
                        unpaidOrder: res.data.data.unpaid[0].orderCount,
                        unpaidTotal: res.data.data.unpaid[0].total,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, 180000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const labelChart1 = this.state.dateLabel;
        const dataChart1 = {
            labels: labelChart1,
            datasets: [
                {
                    lineTension: 0.5,
                    label: "Unpaid",
                    backgroundColor: 'rgb(43, 42, 76)',
                    borderColor: "rgb(43, 42, 76)",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'rgb(43, 42, 76)',
                    pointBackgroundColor: "rgb(43, 42, 76)",
                    data: this.state.dataChart2,
                },
                {
                    lineTension: 0.5,
                    label: "Paid",
                    backgroundColor: 'rgb(179, 19, 18)',
                    borderColor: "rgb(179, 19, 18)",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'rgb(179, 19, 18)',
                    pointBackgroundColor: "rgb(179, 19, 18)",
                    data: this.state.dataChart1,
                },


            ],
        };
        const dataOption1 = {
            plugins: {

                tooltips: {
                    mode: 'index',
                    titleFontSize: 12,
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    backgroundColor: '#fff',
                    titleFontFamily: 'Montserrat',
                    bodyFontFamily: 'Montserrat',
                    cornerRadius: 3,
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": Rp " + Number(
                                tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
                                    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," +
                                        c : c;
                                });
                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        fontFamily: 'Montserrat',
                    },
                },

                title: {
                    display: false,
                    text: 'Normal Legend'
                }
            },
            scales: {
                x: {
                    display: true,


                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Rupiah',
                    },

                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        },
                        y: [{
                            grid: {
                                drawBorder: false,
                                color: 'rgba(235,237,242,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                            }
                        }],
                    }

                }

            },
        }

        return (
            <>

                <div>
                    <div className="row" >
                        <div className="col-lg-12" >
                            <div className="single_element" style={{ padding: "30px 30px 0 30px" }}>
                                <div className="quick_activity" >
                                    <div className="row" >
                                        <div className="white_box mb_10 min_430" style={{ padding: "10px" }}>

                                            <div className="row d-flex justify-content-center" >
                                                <div className="main-title">
                                                    <h3 className="mb-0">Today BPPBJ E-Order Summary - {moment(new Date()).format("DD MMMM YYYY")}</h3>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 ">
                                                    <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "#B31312", marginTop: "20px" }}>
                                                        <h3 style={{ color: "white", fontSize: "25px" }}> Paid Transaction</h3>

                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h4 style={{ color: "white", }}> Order</h4>
                                                            </div>
                                                            <div className='col-6'>
                                                                <h4 style={{ float: "right" }}>
                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder", }} displayType={'text'} value={this.state.paidOrder} thousandSeparator={true} />
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h4 style={{ color: "white", }}>Revenue</h4>
                                                            </div>
                                                            <div className='col-6' >
                                                                <h4 style={{ float: "right" }}>
                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder", }} displayType={'text'} value={this.state.paidTotal ?? 0} thousandSeparator={true} />
                                                                </h4>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div >
                                                <div className="col-xl-6  col-lg-6 ">
                                                    <div className="single_quick_activity" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "#2B2A4C", marginTop: "20px" }}>
                                                        <h3 style={{ color: "white", fontSize: "25px" }}> Unpaid Transaction</h3>

                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h4 style={{ color: "white", }}> Order</h4>
                                                            </div>
                                                            <div className='col-6' >
                                                                <h4 style={{ float: "right" }}>
                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder", }} displayType={'text'} value={this.state.unpaidOrder} thousandSeparator={true} />
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h4 style={{ color: "white", }}>Revenue</h4>
                                                            </div>
                                                            <div className='col-6' >
                                                                <h4 style={{ float: "right" }}>
                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder", }} displayType={'text'} value={this.state.unpaidTotal ?? 0} thousandSeparator={true} />
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>

                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                    <div className="row">
                        <div className="col-lg-12 col-xl-12">
                            <div className="white_box mb_30" style={{ padding: "0px 30px 30px 30px" }}>
                                <div className="box_header" >
                                    <div className="main-title">
                                        <h3 className="mb-0">Weekly BPPBJ E-Order Summary</h3>
                                    </div>
                                </div>
                                <Bar
                                    width="100%"
                                    height="70%"
                                    data={dataChart1}
                                    options={dataOption1}
                                />
                            </div>
                        </div>

                    </div>
                </div>



            </>
        );
    }
}