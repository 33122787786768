import React, { Component } from "react";



export default class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer_part">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <div className="footer_iner text-center">
                                    <p>2022 © Develop by<a href="https://jxboard.co.id/"> Jakarta Experience Board</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>




        );
    }
}