import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";

import AuthService from "./services/auth.service";

//Component Here
import Login from "./components/login.component";
import Home from "./components/home.component";
import DSRGenerate from "./components/pages/dsrReports/dsr.component";
import MSRGenerate from "./components/pages/mthReports/msr.component";
import Navbar from "./components/layouts/navbar.component";
import Footer from "./components/layouts/footer.component";
import Sidebar from "./components/layouts/sidebar.component";
import UserProfile from "./components/pages/userProfile/user-profile.component";
import EventBus from "./common/EventBus";
import UserManagement from "./components/pages/userManagement/user-management.component";
import LogCombined from "./components/pages/logReports/log-combined.component";
import LogDailyCalculate from "./components/pages/logReports/log-daily-calculate.component";
import LogDailyPull from "./components/pages/logReports/log-daily-pull.component";
import LogMonthlyCalculate from "./components/pages/logReports/log-monthly-calculate.component";
import LogMonthlyPull from "./components/pages/logReports/log-monthly-pull.component";
import UpdateGetDataMonthly from "./components/pages/updateData/monthly-update-get.component";
import UpdateResetDataMonthly from "./components/pages/updateData/monthly-update-reset.component";
import UpdateResetDataDaily from "./components/pages/updateData/daily.update-reset.component";
import UpdateDailyServerDate from "./components/pages/updateData/daily-one-server-date.component";
import SMRDailyGenerate from "./components/pages/summaryDaily/summary-daily.component";
import SMRMonthlyGenerate from "./components/pages/summaryMonthly/summary-monthly.component";
import EOrderReport from "./components/pages/eOrderReport/e-order.component";
import OTAReport from "./components/pages/otaReport/ota-report.component";
//Route Def Here
import PrivateRoutes from "./services/privateRoute";
import PublicRoutes from "./services/publicRoute";
//Specific Route Here
import AdminRoutes from "./services/adminRoute";
import DireksiRoutes from "./services/direksiRoute";
import SegmentationReport from "./components/pages/segmentationReports/segmentation.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: Object.values(user.role.id).includes(5),
        showAdminBoard: Object.values(user.role.id).includes(1),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;
    return (
      <>
        {currentUser && <Sidebar />}
        <section className={currentUser && "main_content dashboard_part"}>
          <Routes>
            <Route exact element={<PrivateRoutes />}>
              <Route path="/home" element={<Home />} />
              <Route exact element={<AdminRoutes />}>
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/log-combined" element={<LogCombined />} />
                <Route
                  path="/log-calculate-daily-report"
                  element={<LogDailyCalculate />}
                />
                <Route
                  path="/log-pull-daily-report"
                  element={<LogDailyPull />}
                />
                <Route
                  path="/log-calculate-monthly-report"
                  element={<LogMonthlyCalculate />}
                />
                <Route
                  path="/log-pull-monthly-report"
                  element={<LogMonthlyPull />}
                />
                <Route
                  path="/update-data-get-monthly"
                  element={<UpdateGetDataMonthly />}
                />
                <Route
                  path="/update-data-reset-monthly"
                  element={<UpdateResetDataMonthly />}
                />
                <Route
                  path="/update-data-reset-daily"
                  element={<UpdateResetDataDaily />}
                />
                <Route
                  path="/update-daily-server-date"
                  element={<UpdateDailyServerDate />}
                />
              </Route>
              <Route exact element={<DireksiRoutes />}>
                <Route
                  path="/summary-daily-generate"
                  element={<SMRDailyGenerate />}
                />
                <Route
                  path="/summary-monthly-generate"
                  element={<SMRMonthlyGenerate />}
                />
                <Route
                  path="/eorder-report-generate"
                  element={<EOrderReport />}
                />
                <Route
                  path="/segmentation-report-generate"
                  element={<SegmentationReport />}
                />

                <Route path="/ota-report-generate" element={<OTAReport />} />
              </Route>
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/dsr-generate" element={<DSRGenerate />} />
              <Route path="/msr-generate" element={<MSRGenerate />} />
            </Route>
            <Route exact element={<PublicRoutes />}>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
            </Route>
          </Routes>
          {currentUser && <Footer />}
        </section>
      </>
    );
  }
}

export default App;
