import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from "sweetalert";
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from "axios";
import authHeader from "../../../services/auth-header";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import moment from "moment";
const user = JSON.parse(localStorage.getItem("user"));
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
export default class DSRGenerate extends Component {
  constructor(props) {
    super(props);
    this.handleGenerate = this.handleGenerate.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      generateHotel: "",
      generateDate: "",
      hotelModalName: "",
      hotelId: "",
      date: "",
      dsrExcel: "",
      dsrPdf: "",
      waitDownload: "",
      unitData: [],
      logGenerateDsr: [],
      showAdminHotel: false,
      currentUser: undefined,
    };
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e?.target?.value,
    });
    this.setState({
      hotelModalName: e.target[e?.target?.selectedIndex].text,
    });
  };
  updateInputValue(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      hotelId: val,
    });
  }

  handleUpdate = (e) => {
    axios
      .get(http.getBaseURL() + "reports/logs", { headers: authHeader() })
      .then((res) => {
        this.setState({ logGenerateDsr: res.data.data });
      })
      .catch(function (error) {});
  };

  handleGenerate = (event) => {
    this.setState({
      waitDownload: "",
    });
    const user = AuthService.getCurrentUser();
    event.preventDefault();
    const config = {
      headers: {
        Authorization: user.access_token,
      },
      responseType: "blob",
    };

    if (user.hotel) {
      this.setState({
        hotelId: user.hotel.id,
        date: this.state.date,
      });
    } else {
      this.setState({
        hotelId: this.state.hotelId,
        date: this.state.date,
      });
    }

    //const url = http.getBaseURL() + 'reports/daily-reports';
    axios
      .get(
        http.getBaseURL() +
          "reports/mx-daily-reports/" +
          this.state.hotelId +
          "/" +
          this.state.date,
        config
      )
      .then((response) => {
        // Create a Blob from the response data
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(pdfBlob);

        // Create a temporary <a> element to trigger the download
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute(
          "download",
          `Daily Report ${
            this.state.hotelModalName + " " + this.state.date
          } .pdf`
        ); // Set the desired filename for the downloaded file

        // Append the <a> element to the body and click it to trigger the download
        document.body.appendChild(tempLink);
        tempLink.click();
        // Clean up the temporary elements and URL
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        this.setState({
          waitDownload: response.statusText,
          dsrPdf: url,
        });
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminHotel: user.role.name.includes("Admin Hotel"),
      });
    }
    const url = http.getBaseURL() + "references/hotels";
    axios
      .get(url)
      .then((res) => {
        this.setState({ unitData: res.data.data });
      })
      .catch(function (error) {});

    axios
      .get(http.getBaseURL() + "reports/logs", { headers: authHeader() })
      .then((res) => {
        this.setState({ logGenerateDsr: res.data.data });
      })
      .catch(function (error) {});
    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable({
          aaSorting: [],
        });
      }, 1000);
    });
    $(function () {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate() - 1;

      var year = dtToday.getFullYear();

      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var maxDate = year + "-" + month + "-" + day;
      $("#txtDate").attr("max", maxDate);
    });
  }

  render() {
    const { showAdminHotel, currentUser } = this.state;
    return (
      <>
        <div className="container-fluid g-0">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="header_iner d-flex justify-content-between align-items-center">
                <div className="sidebar_icon d-lg-none">
                  <i className="ti-menu"></i>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      margin: "0 10px",
                    }}
                  >
                    Generate DSR Report
                  </span>
                </div>
                <div className="serach_field-area">
                  <div className="search_inner">
                    <h2>Generate DSR Report</h2>
                  </div>
                </div>
                <Navbar />
              </div>
            </div>
          </div>
        </div>

        <div className="main_content_iner">
          <div className="container-fluid plr_30 body_white_bg pt_30">
            <form onSubmit={this.handleGenerate}>
              <div className="row">
                {showAdminHotel ? (
                  <div className="col-4" style={{ padding: "15px" }}>
                    <div className="form-page mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Unit
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={currentUser.hotel.name}
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-4" style={{ padding: "15px" }}>
                    <div className="form-page mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        {" "}
                        Unit
                      </label>
                      <select
                        className="form-control"
                        name="hotelId"
                        onChange={this.handleChange}
                      >
                        <option>Pilih Unit</option>
                        {this.state.unitData?.map((result) => {
                          return (
                            <option value={result.id} name={result.name}>
                              {result.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-4" style={{ padding: "15px" }}>
                  <div className="form-page mb-3">
                    <label for="exampleInputPassword1" className="form-label">
                      {" "}
                      Date
                    </label>
                    <input
                      type="date"
                      id="txtDate"
                      className="form-control"
                      name="date"
                      placeholder="Generate Date"
                      onChange={this.handleChange}
                      validations={[required]}
                    />
                  </div>
                </div>
                <div
                  className="col-4"
                  style={{
                    padding: "45px 15px",
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    data-bs-toggle="modal"
                    data-bs-target="#modal-download"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </form>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="QA_section">
                  <div className="white_box_tittle list_header">
                    <h4>Daily Summary Reports Log</h4>
                    <div className="box_right d-flex lms_block"></div>
                  </div>
                  <div className="QA_table" style={{ overflow: "scroll" }}>
                    <table id="example" className="table lms_table_active">
                      <thead>
                        <tr>
                          <th scope="col">Generate Time</th>
                          <th scope="col">Unit</th>
                          <th scope="col">Generate By</th>
                          <th scope="col">DSR Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.logGenerateDsr.map((result, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {moment(result.exportDate).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </td>
                              <td>{result.hotelName}</td>
                              <td>{result.userName}</td>
                              <td>{result.reportDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal-blur fade"
          id="modal-download"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            {this.state.hotelId && this.state.date ? (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Daily Sales Report | {this.state.hotelModalName} |{" "}
                    {this.state.date}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container mt-3 MB-3">
                    {this.state.waitDownload ? (
                      <div className="col-xl-12">
                        <div
                          className="row text-center"
                          style={{ margin: "20px" }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            File Berhasil Diunduh
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="col-xl-12">
                        <div className="row text-center">
                          <div class="loader"></div>
                        </div>
                        <div
                          className="row text-center"
                          style={{ margin: "20px" }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Mohon Tunggu Sampai Proses Kalkulasi Selesai
                          </p>
                          <span>Jangan Tutup Halaman Pop-Up ini</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Daily Sales Report {this.state.hotelModalName}{" "}
                    {this.state.date}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container mt-3 MB-3">
                    <div className="col-xl-12">
                      <div
                        className="row text-center"
                        style={{ margin: "20px" }}
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          Unit dan Tanggal Harus dipilih terlebih dahulu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
