import React, { Component } from "react";
class PageParseTitle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: 'Dashboard',
        };
    }

    parseTitle = (data) => {
        this.setState({
            title: data
        })
    }


    pageTitle() {
        return this.state.title;
    }

    componentDidMount() {
        this.setState({
            title: 'Dashboard'
        })
    }
}

export default new PageParseTitle();
