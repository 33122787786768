import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from 'sweetalert';
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import DatePicker from 'react-datepicker';
import addMonths from 'date-fns/addMonths'
import "react-datepicker/dist/react-datepicker.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JSZip from "jszip";
import $ from 'jquery';
import moment from "moment";
window.JSZip = JSZip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const user = JSON.parse(localStorage.getItem('user'));
// Get a date object for the current time
var aMonth = new Date();

// Set it to one month ago
aMonth.setMonth(aMonth.getMonth() - 1);
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class UpdateResetDataDaily extends Component {
    constructor(props) {
        super(props);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);

        this.state = {
            generateHotel: '',
            period: '',
            hotelId: '',
            generateDate: '',
            hotelModalName: '',
            hotelId: '',
            date: '',
            resSuccess: '',
            unitData: [],
            loGetDayData: [],
            showAdminHotel: false,
            currentUser: undefined,
            theMonth: aMonth,
            startDate: '',
            endDate: '',
        };
    }

    handleFilter = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        event.preventDefault();

        const config = {
            headers: {
                "Authorization": user.access_token,
            },
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,

            }
        };
        axios.get(http.getBaseURL() + 'logs/daily/data', config).then(res => {
            this.setState({ loGetDayData: res.data.data });
            console.log(res.data.data);
        });

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.setState({
            hotelModalName: e.target[e.target.selectedIndex].text
        })
        console.log(e.target.value)
    }
    handleChangeMonth = (date) => {
        const dateMonth = moment(date).format("YYYYMM").toLocaleString();
        this.setState({
            theMonth: date,
            date: dateMonth
        })
        console.log(moment(date).format("YYYYMM").toLocaleString())
        console.log(this.state.theMonth)
    }
    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
            hotelId: val
        });
    }

    handleUpdate = (e) => {
        axios.get(http.getBaseURL() + 'reports/logs', { headers: authHeader() })
            .then(res => {
                this.setState({ loGetDayData: res.data.data });
            })
            .catch(function (error) {
            });
    }


    handleGenerate = event => {
        const user = AuthService.getCurrentUser();
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.access_token,
            },
            params: {
                date: this.state.date,
                hotelId: this.state.hotelId,
            }
        };
        axios.delete(http.getBaseURL() + 'daily/reset-data', config).then(res => {
            console.log(res)
            this.setState({
                resSuccess: res.data.message,
            })
        });

    }


    componentDidMount() {
        const user = AuthService.getCurrentUser();
        const config = {
            headers: {
                "Authorization": user.access_token,
            }
        };
        if (user) {
            this.setState({
                currentUser: user,
                showAdminHotel: user.role.name.includes('Admin Hotel'),
            });
        }
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
                console.log(res.data.data)
            })
            .catch(function (error) {
            });

        axios.get(http.getBaseURL() + 'logs/daily/data', config).then(res => {
            this.setState({ loGetDayData: res.data.data });
            console.log(res.data.data);
        });


        $(document).ready(function () {
            setTimeout(function () {

                $('#history-gsm').DataTable({
                    pagingType: "full_numbers",
                    pageLength: 20,
                    processing: true,
                    dom: "Bfrtip",
                    select: {
                        style: "single",
                    },
                    buttons: [
                        {
                            extend: "pageLength",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "copy",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "excel",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "pdf",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "print",
                            customize: function (win) {
                                $(win.document.body).css("font-size", "10pt");
                                $(win.document.body)
                                    .find("table")
                                    .addClass("compact")
                                    .css("font-size", "inherit");
                            },
                            className: "btn btn-primary bg-primary",
                        },
                    ],

                    lengthMenu: [
                        [10, 20, 30, 50, -1],
                        [10, 20, 30, 50, "All"],
                    ],
                    columnDefs: [
                        {
                            targets: 0,
                            render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                            },
                        },
                    ],
                });
            }, 1000);
        });
        $(function () {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate() - 1;

            var year = dtToday.getFullYear();

            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            $('#txtDate').attr('max', maxDate);
        });
    }

    render() {
        const { showAdminHotel, currentUser } = this.state;
        return (
            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Reset Daily Data Hotels</span>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Reset Daily Data Hotels</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner">
                    <div className="container-fluid plr_30 body_white_bg pt_30">

                        <form onSubmit={this.handleGenerate} >
                            <div className="row">
                                <div className="col-4" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Choose Unit</label>
                                        <select
                                            className="form-control"
                                            name="hotelId"
                                            onChange={this.handleChange}

                                        >
                                            <option>Pilih Unit</option>

                                            {this.state.unitData?.map((result) => {
                                                return (
                                                    <option value={result.id}>{result.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>


                                </div>

                                <div className="col-4" style={{ padding: "15px" }}>


                                    <div className="form-page mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Choose Day</label>
                                        <input
                                            type="date"
                                            id="txtDate"
                                            className="form-control"
                                            name="date"
                                            placeholder="Generate Date"
                                            onChange={this.handleChange}
                                            validations={[required]}
                                        />
                                    </div>


                                </div>
                                <div className="col-4" style={{
                                    padding: "45px 15px"
                                }}>
                                    <button type="submit" className="btn btn-danger" style={{ width: "100%" }} data-bs-toggle="modal" data-bs-target="#modal-dialog" >Reset</button>
                                </div>
                            </div>
                        </form>
                        <div className="row justify-content-center">
                            <div className="col-12">

                                <div className="QA_section">
                                    <div className="white_box_tittle list_header">
                                        <h4>Calculation of Dily Report Data</h4>
                                        <div className="box_right d-flex lms_block">
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <form onSubmit={this.handleFilter}>
                                            <div className="row">

                                                <div className="col-lg-4 col-md-12 col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Tanggal mulai</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="startDate"
                                                            placeholder="Input Due Date"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12 col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Tanggal Akhir</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="endDate"
                                                            placeholder="Input Due Date"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-12 col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Filter</label>
                                                        <button type="submit" className="btn btn-primary ms-auto w-100" >Generate Log</button>

                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                    <div style={{ marginTop: "20px", overflow: "scroll" }}>
                                        <table className="table responsive">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">hotelId</th>
                                                    <th scope="col">Server</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Started Time</th>
                                                    <th scope="col">Completed Time</th>
                                                    <th scope="col">Created At</th>
                                                    <th scope="col">Updated At</th>
                                                    <th scope="col">Reseted At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loGetDayData.map((result, i) => {
                                                    return (

                                                        <tr key={i}>
                                                            <td>{result.id}</td>
                                                            <td>{result.hotelId}</td>
                                                            <td>{result.server}</td>
                                                            <td>{result.date}</td>
                                                            <td>{result.status}</td>
                                                            <td>
                                                                {moment(result.startedTime).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </td>
                                                            <td>
                                                                {moment(result.completedTime).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </td>
                                                            <td>
                                                                {moment(result.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </td>
                                                            <td>
                                                                {moment(result.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </td>
                                                            <td>
                                                                {moment(result.resetedAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal modal-blur fade" id="modal-dialog" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        {this.state.hotelId && this.state.date ? (
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Reset Daily Data Hotels | {this.state.hotelModalName} | {this.state.date}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container mt-3 MB-3">
                                        <div className="col-xl-12">
                                            <div className="row text-center" style={{ margin: "20px" }}>
                                                <p style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}>{this.state.resSuccess}</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Reset Daily Data Hotels | {this.state.hotelModalName} | {this.state.date}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container mt-3 MB-3">
                                        <div className="col-xl-12">
                                            <div className="row text-center" style={{ margin: "20px" }}>
                                                <p style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}>Unit dan Tanggal harus dipilih terlebih dahulu</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                    </div>
                </div >
            </>
        );
    }
}
