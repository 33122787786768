import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from 'sweetalert';
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NumericFormat } from 'react-number-format';
import { downloadExcel } from "react-export-table-to-excel";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import moment from "moment";
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
    }).format(number);
}

const columns = [
    { header: 'UMKM Name', key: 'umkm' },
    { header: 'Binaan', key: 'binaanType' },
    { header: 'Purchase Order Number', key: 'noPurchaseOrder' },
    { header: 'Order', key: 'order' },
    { header: 'Order Date', key: 'orderDate' },
    { header: 'Total Price', key: 'totalPrice' },
    { header: 'Delivery Status', key: 'deliveryStatus' },
    { header: 'Payment Status', key: 'paymentStatus' },
    { header: 'Transaction Year', key: 'transactionYear' }
];


const workSheetName = 'Worksheet-1';
const workBookName = 'MyWorkBook';
const myInputId = 'myInput';
const workbook = new Excel.Workbook();


export default class EOrderReport extends Component {
    constructor(props) {
        super(props);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);


        this.state = {
            filtervIew: '',
            generateHotel: '',
            generateDate: '',
            hotelModalName: '',
            hotelId: '',
            date: '',
            dsrExcel: '',
            dsrPdf: '',
            waitDownload: '',
            emptyData: '',
            unitData: [],
            eOrder: [],
            showAdminHotel: false,
            currentUser: undefined,
            tableToogler: false
        };
    }

    handleChangeFilter = (e) => {
        this.setState({ tableToogler: false });


        this.setState({
            filtervIew: e.target.value
        });
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })

    }
    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
            hotelId: val
        });

    }

    handleGenerate = event => {
        this.setState({
            eOrder: [],
            tableToogler: true,
            emptyData: '',
            waitDownload: '',
        });

        const user = AuthService.getCurrentUser();
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.access_token,
            },
            params: {
                filter: this.state.filter,
                endDate: this.state.endDate,
                startDate: this.state.startDate,
                paymentStatus: this.state.paymentStatus,
                deliveryStatus: this.state.deliveryStatus
            }
        };

        //const url = http.getBaseURL() + 'reports/daily-reports';
        axios.get(http.getBaseURL() + 'reports/eorders', config)
            .then(res => {
                this.setState({ eOrder: res.data });
                console.log(res.data)
            })
            .catch((error) => {
                this.setState({ emptyData: error.response.data.code });
            });
    }

    exportPDF = (e) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "E-Order BPPBJ Report";
        const headers = [["UMKM NAME", "BINAAN", "PURCHASE ORDER", "ORDER", "ORDER DATE", "TOTAL PRICE", "DELIVERY STATUS", "PAYMENT STATUS", "TRS YEAR"]];

        const data = this.state.eOrder.data.map(elt => [elt.umkm,
        elt.binaanType,
        elt.noPurchaseOrder,
        elt.order,
        elt.orderDate,
        rupiah(elt.totalPrice),
        elt.deliveryStatus,
        elt.paymentStatus,
        elt.transactionYear]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("E-Order BPPBJ Report.pdf")
    }

    exportExcelv3 = async () => {
        try {

            const myInput = document.getElementById(myInputId);
            const fileName = "E-Order BPPBJ Report" + " " || workBookName;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // merge a range of cells

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = columns;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 10;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            this.state.eOrder.data.forEach(singleData => {
                worksheet.addRow(singleData);
            });

            worksheet.spliceRows(1, 0, ["E-Order BPPBJ Report"]);
            worksheet.mergeCells('A1:I1');
            // updated the font for first row.
            worksheet.getRow(2).font = {
                name: 'Arial',
                color: { argb: '00000000' },
                family: 2,
                size: 12,
                bold: true
            };

            worksheet.getCell('A1').font = {
                name: 'Arial Black',
                color: { argb: '00000000' },
                family: 2,
                size: 14,
                bold: true
            };

            worksheet.getCell('A1').fill = {
                type: 'pattern',
                pattern: 'darkTrellis',
                fgColor: { argb: 'ffd9d5d4' },
                bgColor: { argb: 'FFD9D5D4' }
            };

            // Set Column 3 to Currency Format

            worksheet.getColumn(6).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';

            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;

                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    };

    componentDidMount() {

        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showAdminHotel: user.role.name.includes('Admin Hotel'),
            });
        }
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
            })
            .catch(function (error) {
            });


        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable({
                    aaSorting: []
                });
            }, 1000);
        });


    }

    render() {
        const { showAdminHotel, currentUser } = this.state;
        const fontTd = {
            color: "black",

        };
        return (

            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Generate E-Order BPPBJ Report</span>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Generate E-Order BPPBJ Report</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner">
                    <div className="container-fluid plr_30 body_white_bg pt_30">

                        <form onSubmit={this.handleGenerate} >
                            <div className="row">
                                <div className="col-12" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label">Choose Filter</label>
                                        <select
                                            className="form-control"
                                            name="filter"
                                            onChange={this.handleChangeFilter}
                                        >
                                            <option value={null}>Silakan Pilih</option>
                                            <option value="today" name="today">Today</option>
                                            <option value="weekly" name="weekly">Weekly</option>
                                            <option value="mtd" name="mtd">Month To date</option>
                                            <option value="ytd" name="ytd">Year to Date</option>
                                            <option value="daterange" name="daterange">Daterange</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="col-6" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label">Status Pembayaran</label>
                                        <select
                                            className="form-control"
                                            name="paymentStatus"
                                            onChange={this.handleChange}
                                        >
                                            <option value="">Silakan Pilih</option>
                                            <option value="Sudah Dibayar" name="Sudah Dibayar">Sudah Dibayar</option>
                                            <option value="Belum Dibayar" name="Belum Dibayar">Belum Dibayar</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-6" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label">Status Pengiriman</label>
                                        <select
                                            className="form-control"
                                            name="deliveryStatus"
                                            onChange={this.handleChange}
                                        >
                                            <option value="">Silakan Pilih</option>
                                            <option value="Selesai" name="Selesai">Selesai</option>
                                            <option value="Sedang Dikirim" name="Sedang Dikirim">Sedang Dikirim</option>
                                            <option value="Pembayaran Selesai" name="Pembayaran Selesai">Pembayaran Selesai</option>
                                        </select>
                                    </div>
                                </div>

                                {this.state.filtervIew == 'daterange' ?
                                    <>
                                        <div className="col-4" style={{ padding: "15px" }}>
                                            <div className="form-page mb-3">
                                                <label for="exampleInputPassword1" className="form-label">Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="startDate"
                                                    placeholder="Generate Date"
                                                    onChange={this.handleChange}
                                                    validations={[required]}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4" style={{ padding: "15px" }}>

                                            <div className="form-page mb-3">
                                                <label for="exampleInputPassword1" className="form-label">End Date Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="endDate"
                                                    placeholder="Generate Date"
                                                    onChange={this.handleChange}
                                                    validations={[required]}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4" style={{
                                            padding: "45px 15px"
                                        }}>
                                            <button type="submit" className="btn btn-primary" style={{ width: "100%" }} >Generate</button>
                                        </div>
                                    </>
                                    :
                                    <div className="col-12" style={{ marginBottom: "15px" }}>
                                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} >Generate</button>
                                    </div>

                                }


                            </div>
                        </form>
                        <div className="row justify-content-center">
                            <div className="col-12">

                                <div className="QA_section">
                                    <div className="white_box_tittle list_header">
                                        <h4>Data Output</h4>
                                        <div className="box_right d-flex lms_block">
                                        </div>
                                    </div>

                                    {this.state.tableToogler == true && this.state.eOrder.data ?

                                        <>
                                            <button className="btn btn-primary" style={{ margin: "0 10px" }} onClick={() => this.exportPDF()}>Generate PDF</button>
                                            <button className="btn btn-primary" style={{ margin: "0 10px" }} onClick={() => this.exportExcelv3()}>Generate Excel</button>
                                            <div className="" style={{ overflow: "scroll" }}>
                                                <table id="" className="table responsive" >
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">UMKM Name</th>
                                                            <th scope="col">Binaan</th>
                                                            <th scope="col">Purchase Order</th>
                                                            <th scope="col">Order</th>
                                                            <th scope="col">Order Date</th>
                                                            <th scope="col">Total Price</th>
                                                            <th scope="col">Delivery Status</th>
                                                            <th scope="col">Payment Status</th>
                                                            <th scope="col">Transaction Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.eOrder.data.map((result, i) => {
                                                            return (
                                                                <tr >
                                                                    <td style={fontTd}>
                                                                        {result.umkm}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.binaanType}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.noPurchaseOrder}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.order}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.orderDate}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        <NumericFormat value={result.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.deliveryStatus}
                                                                    </td>
                                                                    <td style={fontTd}>
                                                                        {result.paymentStatus}
                                                                    </td>

                                                                    <td style={fontTd}>
                                                                        {result.transactionYear}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                    {this.state.emptyData == 404 && (
                                        <>
                                            <div className="col-12">
                                                <h4 className="text-center mb-5">Data Tidak Dapat Ditemukan</h4>
                                            </div>
                                        </>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
