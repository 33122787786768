import React, { Component } from 'react';
import Logo from '../../../assets/jxb-black.png';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import moment from "moment";

import http from '../../../services/http';
export default class SlideMain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        const config = {
            headers: {
                'X-APP-KEY': 'PTJAKTOURJXBPTJAKTOURJXBPTJAKTOURJXB'
            }
        };
        const url = http.getBaseURL() + 'dashboard';
        axios.get(url, config)
            .then(res => {
                this.setState({ data: res.data });
            })
            .catch(function (error) {
            });
    }

    render() {
        const labelChart1 = this.state.data?.data?.detail?.ccp?.dataGraphic?.lastWeekDays;
        const labelChart2 = this.state.data?.data?.detail?.ccp?.dataGraphic?.lastWeekYesterdays;
        const dataChart1 = {
            labels: labelChart2,
            datasets: [
                {
                    lineTension: 0.5,
                    label: "GCB",
                    backgroundColor: 'transparent',
                    borderColor: "#f0ae00",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#f0ae00",
                    data: this.state.data?.data?.detail?.gcb?.dataGraphic?.lastWeekSummaryRevenues,
                },
                {
                    lineTension: 0.5,
                    label: "GCR",
                    backgroundColor: 'transparent',
                    borderColor: "#0014FF",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#0014FF",
                    data: this.state.data?.data?.detail?.gcr?.dataGraphic?.lastWeekSummaryRevenues,
                },
                {
                    lineTension: 0.5,
                    label: "Al Hijra",
                    backgroundColor: 'transparent',
                    borderColor: "#379237",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#379237",
                    data: this.state.data?.data?.detail?.acp?.dataGraphic?.lastWeekSummaryRevenues,
                },
                {
                    lineTension: 0.5,
                    label: "Sunter",
                    backgroundColor: 'transparent',
                    borderColor: "#2B3A55",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#2B3A55",
                    data: this.state.data?.data?.detail?.ahs?.dataGraphic?.lastWeekSummaryRevenues,
                },
                {
                    lineTension: 0.5,
                    label: "Plumpang",
                    backgroundColor: 'transparent',
                    borderColor: "#f53c79",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#f53c79",
                    data: this.state.data?.data?.detail?.apl?.dataGraphic?.lastWeekSummaryRevenues,
                },
                {
                    lineTension: 0.5,
                    label: "Cempaka Putih",
                    backgroundColor: 'transparent',
                    borderColor: "#7F669D",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#7F669D",
                    data: this.state.data?.data?.detail?.ccp?.dataGraphic?.lastWeekSummaryRevenues,
                },
                {
                    lineTension: 0.5,
                    label: "Pulomas",
                    backgroundColor: 'transparent',
                    borderColor: "#CE7777",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#CE7777",
                    data: this.state.data?.data?.detail?.cpl?.dataGraphic?.lastWeekSummaryRevenues,
                },

            ],
        };
        const dataOption1 = {
            plugins: {

                tooltips: {
                    mode: 'index',
                    titleFontSize: 12,
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    backgroundColor: '#fff',
                    titleFontFamily: 'Montserrat',
                    bodyFontFamily: 'Montserrat',
                    cornerRadius: 3,
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": Rp " + Number(
                                tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
                                    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," +
                                        c : c;
                                });
                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        fontFamily: 'Montserrat',
                    },
                },

                title: {
                    display: false,
                    text: 'Normal Legend'
                }
            },
            scales: {
                x: {
                    display: true,

                    grid: {
                        display: false,
                        drawBorder: false
                    },

                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Rupiah',
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        },
                        y: [{
                            grid: {
                                drawBorder: false,
                                color: 'rgba(235,237,242,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                            }
                        }],
                    }

                }

            },
        }

        const dataChart2 = {
            labels: labelChart1,
            datasets: [
                {
                    lineTension: 0.5,
                    label: "GCB",
                    backgroundColor: 'transparent',
                    borderColor: "#f0ae00",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#f0ae00",
                    data: this.state.data?.data?.detail?.gcb?.dataGraphic?.lastWeekOccupancies,
                },
                {
                    lineTension: 0.5,
                    label: "GCR",
                    backgroundColor: 'transparent',
                    borderColor: "#0014FF",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#0014FF",
                    data: this.state.data?.data?.detail?.gcr?.dataGraphic?.lastWeekOccupancies,
                },
                {
                    lineTension: 0.5,
                    label: "Al Hijra",
                    backgroundColor: 'transparent',
                    borderColor: "#379237",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#379237",
                    data: this.state.data?.data?.detail?.acp?.dataGraphic?.lastWeekOccupancies,
                },
                {
                    lineTension: 0.5,
                    label: "Sunter",
                    backgroundColor: 'transparent',
                    borderColor: "#2B3A55",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#2B3A55",
                    data: this.state.data?.data?.detail?.ahs?.dataGraphic?.lastWeekOccupancies,
                },
                {
                    lineTension: 0.5,
                    label: "Plumpang",
                    backgroundColor: 'transparent',
                    borderColor: "#f53c79",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#f53c79",
                    data: this.state.data?.data?.detail?.apl?.dataGraphic?.lastWeekOccupancies,
                },
                {
                    lineTension: 0.5,
                    label: "Cempaka Putih",
                    backgroundColor: 'transparent',
                    borderColor: "#7F669D",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#7F669D",
                    data: this.state.data?.data?.detail?.ccp?.dataGraphic?.lastWeekOccupancies,
                },
                {
                    lineTension: 0.5,
                    label: "Pulomas",
                    backgroundColor: 'transparent',
                    borderColor: "#CE7777",
                    borderWidth: 3,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: "#CE7777",
                    data: this.state.data?.data?.detail?.cpl?.dataGraphic?.lastWeekOccupancies,
                },

            ],
        };
        const dataOption2 = {
            plugins: {

                tooltips: {
                    mode: 'index',
                    titleFontSize: 12,
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    backgroundColor: '#fff',
                    titleFontFamily: 'Montserrat',
                    bodyFontFamily: 'Montserrat',
                    cornerRadius: 3,
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": Rp " + Number(
                                tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
                                    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," +
                                        c : c;
                                });
                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        fontFamily: 'Montserrat',
                    },
                },

                title: {
                    display: false,
                    text: 'Normal Legend'
                }
            },
            scales: {
                x: {
                    display: true,

                    grid: {
                        display: false,
                        drawBorder: false
                    },

                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Percent',
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        },
                        y: [{
                            grid: {
                                drawBorder: false,
                                color: 'rgba(235,237,242,1)',
                                zeroLineColor: 'rgba(235,237,242,1)'
                            }
                        }],
                    }

                }

            },
        }
        return (

            <>
                {this.state.data.data ?
                    <div>
                        <div className="row" >
                            <div className="col-lg-12" >
                                <div className="single_element" style={{ padding: "20px" }}>
                                    <div className="quick_activity" >
                                        <div className="row" >
                                            <div className="white_box mb_10 min_430" style={{ padding: "10px" }}>
                                                <div className="row" >
                                                    <div className="col-lg-12 col-xl-12" >
                                                        <div className="single_quick_activity" style={{ background: "#1c5ddc", marginTop: "20px" }}>
                                                            <div className="row" >
                                                                <div className="col-lg-6 col-sm-12" >
                                                                    <h4>Total Revenue - {moment(this.state.data.data.date?.yesterday).format("MMMM Do YYYY")}</h4>
                                                                    <h3>Rp <span> </span>
                                                                        <span className="" style={{ color: "white", fontWeight: "bolder" }}>
                                                                            {this.state.data ?
                                                                                <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.sumAllRevenue} thousandSeparator={true} />
                                                                                : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                        </span>
                                                                    </h3 >
                                                                    <p>Summary All Hotel</p>
                                                                    <p style={{ fontWeight: "bold" }}>Average Occupancy All Hotel : {this.state.data.data?.yesterdayAvgOccupancyAllHotels} %</p>
                                                                </div >
                                                                <div className="col-lg-6 col-sm-12" >
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                GCB</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.gcb?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.gcb?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                GCR</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.gcr?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.gcr?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                CCP</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.ccp?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.ccp?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                CPL</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.cpl?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.cpl?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                ACP</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.acp?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.acp?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                AHS</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.ahs?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.ahs?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-4'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                APL</p>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <NumericFormat style={{ color: "white", fontWeight: "bolder" }} displayType={'text'} value={this.state.data?.data?.detail?.apl?.allRevenue} thousandSeparator={true} />
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>({this.state.data?.data?.detail?.apl?.yesterdayOccupancy} %)</span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div >
                                                            </div >

                                                        </div >
                                                    </div >
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="white_box mb_30">
                                                            <div className="box_header">
                                                                <div className="main-title">
                                                                    <h3 className="mb-0">Weekly Summary Hotel Revenue</h3>
                                                                </div>
                                                            </div>
                                                            <Line
                                                                data={dataChart1}
                                                                options={dataOption1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div className="row" >
                            <div className="col-lg-12" >
                                <div className="single_element" style={{ padding: "20px" }}>
                                    <div className="quick_activity" >
                                        <div className="row" >
                                            <div className="white_box mb_10 min_430" style={{ padding: "10px" }}>

                                                <div className="row" >
                                                    <div className="col-lg-12 col-xl-12" >
                                                        <div className="single_quick_activity" style={{ background: "#db8f00", marginTop: "20px" }}>
                                                            <div className="row" >
                                                                <div className="col-lg-6 col-sm-12" >
                                                                    <h4>Total Occupancy - {moment(this.state.data.data.date?.today).format("MMMM Do YYYY")}</h4>
                                                                    <h3><i className="fa fa-hotel" aria-hidden="true"></i> <span className=""
                                                                        style={{ color: "white", fontWeight: "bolder" }}>
                                                                        <span className="" style={{ color: "white", fontWeight: "bolder" }}>
                                                                            {this.state.data ?
                                                                                <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                    {this.state.data?.data?.sumOccupancy}
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                </span>
                                                                                : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                        </span>
                                                                    </span > </h3 >
                                                                    <p>Summary All Hotel</p>
                                                                </div >
                                                                <div className="col-lg-6 col-sm-12" >
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                GCB</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>

                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.gcb?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                GCR</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.gcr?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                CCP</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.ccp?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                CPL</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.cpl?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                ACP</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.acp?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                AHS</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.ahs?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}>
                                                                                APL</p>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span style={{ color: "white" }}>
                                                                                {this.state.data ?
                                                                                    <span style={{ color: "white", fontWeight: "bolder" }}>
                                                                                        {this.state.data?.data?.detail?.apl?.occupancy}
                                                                                        <span style={{ color: "white", fontWeight: "bolder" }}> %</span>
                                                                                    </span>
                                                                                    : <span style={{ color: "white", fontWeight: "bolder" }}> No data yet</span>}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div >
                                                            </div >
                                                        </div >
                                                    </div >
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="white_box mb_30">
                                                            <div className="box_header">
                                                                <div className="main-title">
                                                                    <h3 className="mb-0">Weekly Summary Hotel Occupancy</h3>
                                                                </div>
                                                            </div>
                                                            <Line
                                                                data={dataChart2}
                                                                options={dataOption2}
                                                            />
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div>
                    :
                    <div className="row" >
                        <div className="col-lg-12" >
                            <div className="single_element" style={{ padding: "20px" }}>
                                <div className="quick_activity" >
                                    <div className="row" >
                                        <div className="white_box mb_10 min_430" style={{ padding: "0px" }}>
                                            <div className='row'>
                                                <div id='loading'>
                                                    <div id="loading-center">
                                                        <div id="loading-center-absolute">
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_one"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_two"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_three"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_four"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_five"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_six"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_seven"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_eight"></div>
                                                            <div class="object" style={{ backgroundColor: "#db8f00" }} id="object_nine"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                }




            </>
        );
    }
}