import React, { Component } from "react";

import { NumericFormat } from "react-number-format";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import http from "../../../services/http";
import moment from "moment";
export default class SlideSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dgcb: [],
      dgcr: [],
      dacp: [],
      dahs: [],
      dapl: [],
      dcop: [],
      dccp: [],

      gcb: "0",
      gcr: "0",
      acp: "0",
      ahs: "0",
      apl: "0",
      cpl: "0",
      ccp: "0",
    };
  }

  detailData(hotelID, dataHotel) {
    const todayNow = new Date();
    const todayNow30 = new Date(new Date().setDate(todayNow.getDate() - 30));
    const config = {
      headers: {
        "x-app-key": "PTJAKTOURJXBPTJAKTOURJXBPTJAKTOURJXB",
      },
      params: {
        filter: "daterange",
        end: todayNow,
        start: todayNow30,
        hotelId: hotelID,
      },
    };
    axios
      .get(http.getBaseURL() + "dashboard/segmentations", config)
      .then((res) => {
        this.setState({ [dataHotel]: res.data });
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.response.data.code);
      });
  }

  componentDidMount() {
    console.log("bisa");
    this.detailData(1, "gcb");
    this.detailData(2, "gcr");
    this.detailData(3, "ccp");
    this.detailData(4, "cpl");
    this.detailData(5, "acp");
    this.detailData(6, "ahs");
    this.detailData(7, "apl");
    this.interval = setInterval(() => {
      this.detailData(1, "gcb");
      this.detailData(2, "gcr");
      this.detailData(3, "ccp");
      this.detailData(4, "cpl");
      this.detailData(5, "acp");
      this.detailData(6, "ahs");
      this.detailData(7, "apl");
    }, 180000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const labelChart1 = ["GCB", "GCR", "CCP", "CPL", "ACP", "AHS", "APL"];
    const { gcb, gcr, ccp, cpl, acp, ahs, apl } = this.state;
    console.log(gcb);
    const dataChart1 = {
      labels: labelChart1,
      datasets: [
        {
          lineTension: 0.5,
          label: "Revenue (Rp)",
          backgroundColor: "rgb(33, 51, 99)",
          borderColor: "rgb(33, 51, 99)",
          borderWidth: 3,
          pointStyle: "circle",
          pointRadius: 5,
          pointBorderColor: "rgb(33, 51, 99)",
          pointBackgroundColor: "rgb(33, 51, 99)",
          data: [
            gcb?.data?.WalkIn?.revenue,
            gcr?.data?.WalkIn?.revenue,
            ccp?.data?.WalkIn?.revenue,
            cpl?.data?.WalkIn?.revenue,
            acp?.data?.WalkIn?.revenue,
            ahs?.data?.WalkIn?.revenue,
            apl?.data?.WalkIn?.revenue,
          ],
        },
      ],
    };
    const dataOption1 = {
      plugins: {
        tooltips: {
          mode: "index",
          titleFontSize: 12,
          titleFontColor: "#000",
          bodyFontColor: "#000",
          backgroundColor: "#fff",
          titleFontFamily: "Montserrat",
          bodyFontFamily: "Montserrat",
          cornerRadius: 3,
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label +
                ": Rp " +
                Number(tooltipItem.yLabel)
                  .toFixed(0)
                  .replace(/./g, function (c, i, a) {
                    return i > 0 && c !== "." && (a.length - i) % 3 === 0
                      ? "," + c
                      : c;
                  })
              );
            },
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            fontFamily: "Montserrat",
          },
        },

        title: {
          display: false,
          text: "Normal Legend",
        },
      },
      scales: {
        x: {
          display: true,
        },
        y: {
          display: true,
          title: {
            display: true,
            text: "Rupiah",
          },

          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              if (parseInt(value) >= 1000) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return value;
              }
            },
            y: [
              {
                grid: {
                  drawBorder: false,
                  color: "rgba(235,237,242,1)",
                  zeroLineColor: "rgba(235,237,242,1)",
                },
              },
            ],
          },
        },
      },
    };
    const dataChart2 = {
      labels: labelChart1,
      datasets: [
        {
          lineTension: 0.5,
          label: "Revenue (Rp)",
          backgroundColor: "rgb(18, 55, 42)",
          borderColor: "rgb(18, 55, 42)",
          borderWidth: 3,
          pointStyle: "circle",
          pointRadius: 5,
          pointBorderColor: "rgb(18, 55, 42)",
          pointBackgroundColor: "rgb(18, 55, 42)",
          data: [
            gcb?.data?.Association?.revenue,
            gcr?.data?.Association?.revenue,
            ccp?.data?.Association?.revenue,
            cpl?.data?.Association?.revenue,
            acp?.data?.Association?.revenue,
            ahs?.data?.Association?.revenue,
            apl?.data?.Association?.revenue,
          ],
        },
      ],
    };
    const dataChart3 = {
      labels: labelChart1,
      datasets: [
        {
          lineTension: 0.5,
          label: "Revenue (Rp)",
          backgroundColor: "rgb(13, 146, 118)",
          borderColor: "rgb(13, 146, 118)",
          borderWidth: 3,
          pointStyle: "circle",
          pointRadius: 5,
          pointBorderColor: "rgb(13, 146, 118)",
          pointBackgroundColor: "rgb(13, 146, 118)",
          data: [
            gcb?.data?.Corporate?.revenue,
            gcr?.data?.Corporate?.revenue,
            ccp?.data?.Corporate?.revenue,
            cpl?.data?.Corporate?.revenue,
            acp?.data?.Corporate?.revenue,
            ahs?.data?.Corporate?.revenue,
            apl?.data?.Corporate?.revenue,
          ],
        },
      ],
    };
    const dataChart4 = {
      labels: labelChart1,
      datasets: [
        {
          lineTension: 0.5,
          label: "Revenue (Rp)",
          backgroundColor: "rgb(251, 168, 52)",
          borderColor: "rgb(251, 168, 52)",
          borderWidth: 3,
          pointStyle: "circle",
          pointRadius: 5,
          pointBorderColor: "rgb(251, 168, 52)",
          pointBackgroundColor: "rgb(251, 168, 52)",
          data: [
            gcb?.data?.Government?.revenue,
            gcr?.data?.Government?.revenue,
            ccp?.data?.Government?.revenue,
            cpl?.data?.Government?.revenue,
            acp?.data?.Government?.revenue,
            ahs?.data?.Government?.revenue,
            apl?.data?.Government?.revenue,
          ],
        },
      ],
    };
    const dataChart5 = {
      labels: labelChart1,
      datasets: [
        {
          lineTension: 0.5,
          label: "Revenue (Rp)",
          backgroundColor: "rgb(127, 39, 255)",
          borderColor: "rgb(127, 39, 255)",
          borderWidth: 3,
          pointStyle: "circle",
          pointRadius: 5,
          pointBorderColor: "rgb(127, 39, 255)",
          pointBackgroundColor: "rgb(127, 39, 255)",
          data: [
            gcb?.data?.OnlineTravelAgent?.revenue,
            gcr?.data?.OnlineTravelAgent?.revenue,
            ccp?.data?.OnlineTravelAgent?.revenue,
            cpl?.data?.OnlineTravelAgent?.revenue,
            acp?.data?.OnlineTravelAgent?.revenue,
            ahs?.data?.OnlineTravelAgent?.revenue,
            apl?.data?.OnlineTravelAgent?.revenue,
          ],
        },
      ],
    };
    const dataChart6 = {
      labels: labelChart1,
      datasets: [
        {
          lineTension: 0.5,
          label: "Revenue (Rp)",
          backgroundColor: "rgb(148, 78, 99)",
          borderColor: "rgb(148, 78, 99)",
          borderWidth: 3,
          pointStyle: "circle",
          pointRadius: 5,
          pointBorderColor: "rgb(148, 78, 99)",
          pointBackgroundColor: "rgb(148, 78, 99)",
          data: [
            gcb?.data?.OfflineTravelAgent?.revenue,
            gcr?.data?.OfflineTravelAgent?.revenue,
            ccp?.data?.OfflineTravelAgent?.revenue,
            cpl?.data?.OfflineTravelAgent?.revenue,
            acp?.data?.OfflineTravelAgent?.revenue,
            ahs?.data?.OfflineTravelAgent?.revenue,
            apl?.data?.OfflineTravelAgent?.revenue,
          ],
        },
      ],
    };
    const todayNow = new Date();
    const todayNow30 = new Date(new Date().setDate(todayNow.getDate() - 30));
    return (
      <>
        <div>
          <div>
            <div class="single_element" style={{ padding: "20px" }}>
              <div className="row white_box" style={{ padding: "10px" }}>
                <div className="col-lg-4 col-xl-4">
                  <div className=" mb_30">
                    <div className="box_header">
                      <div className="main-title">
                        <h3 className="mb-0">
                          Walk In | {moment(todayNow30).format("DD MMMM YYYY")}{" "}
                          - {moment(todayNow).format("DD MMMM YYYY")}
                        </h3>
                      </div>
                    </div>
                    <Bar
                      width="100%"
                      height="70%"
                      data={dataChart1}
                      options={dataOption1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4">
                  <div className=" mb_30">
                    <div className="box_header">
                      <div className="main-title">
                        <h3 className="mb-0">
                          Association |{" "}
                          {moment(todayNow30).format("DD MMMM YYYY")} -{" "}
                          {moment(todayNow).format("DD MMMM YYYY")}
                        </h3>
                      </div>
                    </div>
                    <Bar
                      width="100%"
                      height="70%"
                      data={dataChart2}
                      options={dataOption1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4">
                  <div className=" mb_30">
                    <div className="box_header">
                      <div className="main-title">
                        <h3 className="mb-0">
                          Corporate |{" "}
                          {moment(todayNow30).format("DD MMMM YYYY")} -{" "}
                          {moment(todayNow).format("DD MMMM YYYY")}
                        </h3>
                      </div>
                    </div>
                    <Bar
                      width="100%"
                      height="70%"
                      data={dataChart3}
                      options={dataOption1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4">
                  <div className=" mb_30">
                    <div className="box_header">
                      <div className="main-title">
                        <h3 className="mb-0">
                          Government |{" "}
                          {moment(todayNow30).format("DD MMMM YYYY")} -{" "}
                          {moment(todayNow).format("DD MMMM YYYY")}
                        </h3>
                      </div>
                    </div>
                    <Bar
                      width="100%"
                      height="70%"
                      data={dataChart4}
                      options={dataOption1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4">
                  <div className=" mb_30">
                    <div className="box_header">
                      <div className="main-title">
                        <h3 className="mb-0">
                          Online Travel Agent |{" "}
                          {moment(todayNow30).format("DD MMMM YYYY")} -{" "}
                          {moment(todayNow).format("DD MMMM YYYY")}
                        </h3>
                      </div>
                    </div>
                    <Bar
                      width="100%"
                      height="70%"
                      data={dataChart5}
                      options={dataOption1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4">
                  <div className=" mb_30">
                    <div className="box_header">
                      <div className="main-title">
                        <h3 className="mb-0">
                          Offline Travel Agent |{" "}
                          {moment(todayNow30).format("DD MMMM YYYY")} -{" "}
                          {moment(todayNow).format("DD MMMM YYYY")}
                        </h3>
                      </div>
                    </div>
                    <Bar
                      width="100%"
                      height="70%"
                      data={dataChart6}
                      options={dataOption1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
