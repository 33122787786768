import React, { Component } from "react";
import Navbar from "../../layouts/navbar.component";
import swal from 'sweetalert';
import AuthService from "../../../services/auth.service";
import http from "../../../services/http";
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NumericFormat } from 'react-number-format';
import { downloadExcel } from "react-export-table-to-excel";
import DatePicker from 'react-datepicker';
import addMonths from 'date-fns/addMonths';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import moment from "moment";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
var aMonth = new Date();
// Set it to one month ago
aMonth.setMonth(aMonth.getMonth());
const dateMonthNow = moment(aMonth).format("YYYYMM").toLocaleString();
const dateMonthNow2 = moment(aMonth).format("MMMM, yyyy").toLocaleString();
const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
    }).format(number);
}

const columns = [
    { header: 'Unit Code', key: 'hotelCode' },
    { header: 'Total Revenue', key: 'totalRevenue' },
    { header: 'Room Revenue', key: 'roomRevenue' },
    { header: 'Room Cost', key: 'roomCost' },
    { header: 'F&B Revenue', key: 'FBRevenue' },
    { header: 'Food Revenue', key: 'foodRevenue' },
    { header: 'Food Cost', key: 'foodCost' },
    { header: 'Beverage Revenue', key: 'beverageRevenue' },
    { header: 'Beverage Cost', key: 'beverageCost' },
    { header: 'ARR', key: 'arr' },
    { header: 'Occupancy', key: 'occupancy' },
    { header: 'Rent Tenant', key: 'rentTenant' },
    { header: 'Member', key: 'member' },
    { header: 'Other Income', key: 'otherIncome' }
];


const workSheetName = 'Worksheet-1';
const workBookName = 'MyWorkBook';
const myInputId = 'myInput';
const workbook = new Excel.Workbook();


export default class SMRMonthlyGenerate extends Component {
    constructor(props) {
        super(props);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);


        this.state = {
            filtervIew: '',
            filter: 'mtd',
            generateHotel: '',
            generateDate: '',
            hotelModalName: '',
            hotelId: '',
            date: '',
            dateString: dateMonthNow2,
            dsrExcel: '',
            dsrPdf: '',
            waitDownload: '',
            unitData: [],
            summaryData: [],
            showAdminHotel: false,
            currentUser: undefined,
            tableToogler: false,
            theMonth: aMonth,
            date: dateMonthNow,
            messageData: ''
        };
    }

    handleChangeFilter = (e) => {
        this.setState({ tableToogler: false });


        this.setState({
            filtervIew: e.target.value
        });
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log(this.state.filtervIew);
    }
    handleChangeMonth = (date) => {
        const dateMonth = moment(date).format("YYYYMM").toLocaleString();
        const dateMonth2 = moment(date).format("MMMM, yyyy").toLocaleString();
        this.setState({
            theMonth: date,
            date: dateMonth,
            dateString: dateMonth2
        })
        console.log(moment(date).format("YYYYMM").toLocaleString())
        console.log(this.state.theMonth)
        console.log(dateMonth2)
    }
    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
        this.setState({
            hotelModalName: e.target[e.target.selectedIndex].text
        })
    }
    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
            hotelId: val
        });

    }

    handleGenerate = event => {
        this.setState({ messageData: '' });
        this.setState({ tableToogler: true });
        this.setState({ summaryData: [] });
        this.setState({
            waitDownload: '',
        })
        const user = AuthService.getCurrentUser();
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.access_token,
            },
            params: {
                period: this.state.date,
            }
        };
        //const url = http.getBaseURL() + 'reports/daily-reports';
        axios.get(http.getBaseURL() + 'monthly/summary/', config)
            .then(res => {
                this.setState({ summaryData: res.data });
                console.log(res.data)
            })
            .catch((error) => {
                console.log(error)
                this.setState({ messageData: error.response.data });

            });
    }

    exportPDF = (e) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Summary Monthly Report" + " " + "-" + " " + this.state.dateString;
        const headers = [["Unit Code", "Total Revenue", "Room Revenue", "Room Cost", "F&B Revenue", "Food Revenue", "Food Cost", "Beverage Revenue", "Beverage Cost", "ARR", "Occupancy", "Rent Tenatnt", "Member", "Other Income"]];

        const data = this.state.summaryData.data.map(elt => [elt.hotelCode,
        rupiah(elt.totalRevenue),
        rupiah(elt.roomRevenue),
        elt.roomCost + '%',
        rupiah(elt.FBRevenue),
        rupiah(elt.foodRevenue),
        elt.foodCost + '%',
        rupiah(elt.beverageRevenue),
        elt.beverageCost + '%',
        rupiah(elt.arr),
        elt.occupancy + '%',
        rupiah(elt.rentTenant),
        rupiah(elt.member),
        rupiah(elt.otherIncome)]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Summary Monthly Report Spreadsheet" + " " + "-" + " " + this.state.dateString)
    }

    exportExcelv3 = async () => {
        try {

            const myInput = document.getElementById(myInputId);
            const fileName = "Summary Monthly Report" + " " + "-" + " " + this.state.dateString || workBookName;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // merge a range of cells

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = columns;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 10;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            this.state.summaryData.data.forEach(singleData => {
                worksheet.addRow(singleData);
            });

            worksheet.spliceRows(1, 0, ["Summary Monthly Report" + " " + "-" + " " + this.state.dateString]);
            worksheet.mergeCells('A1:N1');
            // updated the font for first row.
            worksheet.getRow(2).font = {
                name: 'Arial',
                color: { argb: '00000000' },
                family: 2,
                size: 12,
                bold: true
            };

            worksheet.getCell('A1').font = {
                name: 'Arial Black',
                color: { argb: '00000000' },
                family: 2,
                size: 14,
                bold: true
            };

            worksheet.getCell('A1').fill = {
                type: 'pattern',
                pattern: 'darkTrellis',
                fgColor: { argb: 'ffd9d5d4' },
                bgColor: { argb: 'FFD9D5D4' }
            };

            // Set Column 3 to Currency Format
            worksheet.getColumn(2).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(3).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(5).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(6).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(8).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(10).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(12).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(13).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(14).numFmt = '"Rp "#,##0.00;[Red]\-"Rp "#,##0.00';
            worksheet.getColumn(4).numFmt = '0.00## #" %";[Red]\-0.00## #" %"';
            worksheet.getColumn(7).numFmt = '0.00## #" %";[Red]\-0.00## #" %"';
            worksheet.getColumn(9).numFmt = '0.00## #" %";[Red]\-0.00## #" %"';
            worksheet.getColumn(11).numFmt = '0.00## #" %";[Red]\-0.00## #" %"';
            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;

                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    };

    exportExcel = (e) => {
        const header = ["Unit Code", "Total Revenue", "Room Revenue", "Room Cost", "F&B Revenue", "Food Revenue", "Food Cost", "Beverage Revenue", "Beverage Cost", "ARR", "Occupancy", "Rent Tenatnt", "Member", "Other Income"];
        const sheetTitle = "Sheet 1"
        const body = this.state.summaryData.data.map(elt => [
            elt.hotelCode,
            rupiah(elt.totalRevenue),
            rupiah(elt.roomRevenue),
            elt.roomCost,
            rupiah(elt.FBRevenue),
            rupiah(elt.foodRevenue),
            elt.foodCost,
            rupiah(elt.beverageRevenue),
            elt.beverageCost,
            rupiah(elt.arr),
            elt.occupancy,
            rupiah(elt.rentTenant),
            rupiah(elt.member),
            rupiah(elt.otherIncome)]);
        downloadExcel({
            fileName: "Summary Monthly Report Spreadsheet" + " " + "-" + " " + this.state.dateString,
            sheet: sheetTitle,
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }

    componentDidMount() {

        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showAdminHotel: user.role.name.includes('Admin Hotel'),
            });
        }
        const url = http.getBaseURL() + 'references/hotels';
        axios.get(url)
            .then(res => {
                this.setState({ unitData: res.data.data });
            })
            .catch(function (error) {
            });


        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable({
                    aaSorting: []
                });
            }, 1000);
        });


    }

    render() {
        const { showAdminHotel, currentUser } = this.state;
        const fontTd = {
            color: "black",
            fontSize: "12px"
        };
        const fontCol = {
            fontSize: "12px"
        };
        const workbook = new Excel.Workbook();

        return (

            <>
                <div className="container-fluid g-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="header_iner d-flex justify-content-between align-items-center">
                                <div className="sidebar_icon d-lg-none">
                                    <i className="ti-menu"></i><span style={{ fontWeight: "bold", fontSize: "18px", margin: "0 10px" }}>Generate Summary Monthly Report</span>
                                </div>
                                <div className="serach_field-area">
                                    <div className="search_inner">
                                        <h2>Generate Summary Monthly Report</h2>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div >

                <div className="main_content_iner">
                    <div className="container-fluid plr_30 body_white_bg pt_30">

                        <form onSubmit={this.handleGenerate} >
                            <div className="row">
                                <div className="col-6" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label"> Month</label>
                                        <DatePicker
                                            selected={this.state.theMonth}
                                            placeholderText="Silakan Pilih Bulan"
                                            onChange={this.handleChangeMonth}
                                            name="theMonth"
                                            dateFormat="MMMM , yyyy"
                                            maxDate={addMonths(new Date(), 0)}
                                            showMonthYearPicker
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-6" style={{ padding: "15px" }}>
                                    <div className="form-page mb-3">
                                        <label for="exampleInputPassword1" className="form-label"> Generate</label>
                                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} >Generate</button>

                                    </div>
                                </div>

                            </div>
                        </form>
                        <div className="row justify-content-center">
                            <div className="col-12">

                                <div className="QA_section">
                                    <div className="white_box_tittle list_header">
                                        <h4>Data Output</h4>
                                        <div className="box_right d-flex lms_block">
                                        </div>
                                    </div>


                                    {this.state.tableToogler ?
                                        <>
                                            {
                                                this.state.summaryData.data ?

                                                    <>
                                                        <button className="btn btn-primary" style={{ margin: "0 10px" }} onClick={() => this.exportPDF()}>Generate PDF</button>
                                                        <button className="btn btn-primary" style={{ margin: "0 10px" }} onClick={() => this.exportExcelv3()}>Generate Excel</button>
                                                        <div className="" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                                            <table id="" className="table responsive" >
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" style={fontCol}>Unit Code</th>
                                                                        <th scope="col" style={fontCol}>Total Revenue(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Room Revenue(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Room Cost(%)</th>
                                                                        <th scope="col" style={fontCol}>F&B Revenue(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Food Revenue(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Food Cost(%)</th>
                                                                        <th scope="col" style={fontCol}>Beverage Revenue(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Beverage Cost(%)</th>
                                                                        <th scope="col" style={fontCol}>ARR(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Occupancy(%)</th>
                                                                        <th scope="col" style={fontCol}>Rent Tenant(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Member(Rp)</th>
                                                                        <th scope="col" style={fontCol}>Other Income(Rp)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.summaryData.data.map((result, i) => {
                                                                        return (
                                                                            <tr >
                                                                                <td key={i} style={fontTd}>{result.hotelCode}</td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.totalRevenue} displayType={'text'} thousandSeparator={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.roomRevenue} displayType={'text'} thousandSeparator={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    {result.roomCost}
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.FBRevenue} displayType={'text'} thousandSeparator={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.foodRevenue} displayType={'text'} thousandSeparator={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    {result.foodCost}
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.beverageRevenue} displayType={'text'} thousandSeparator={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    {result.beverageCost}
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.arr} displayType={'text'} thousandSeparator={true} decimalScale={true} />

                                                                                </td>

                                                                                <td style={fontTd}>
                                                                                    {result.occupancy}
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.rentTenant} displayType={'text'} thousandSeparator={true} decimalScale={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.member} displayType={'text'} thousandSeparator={true} decimalScale={true} />
                                                                                </td>
                                                                                <td style={fontTd}>
                                                                                    <NumericFormat value={result.otherIncome} displayType={'text'} thousandSeparator={true} decimalScale={true} />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>


                                                            </table>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.messageData.status ?
                                                            <div style={{ textAlign: "center" }}>
                                                                <div className="col-xl-12">
                                                                    <div className="row text-center" style={{ margin: "20px" }}>
                                                                        <h1 style={{ fontSize: "100px", color: "black", fontWeight: "bold" }}>{this.state.messageData.code} </h1>
                                                                        <p style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}> {this.state.messageData.status} </p>
                                                                        <p style={{ fontSize: "12px", color: "black", fontWeight: "bold" }}> {this.state.messageData.message} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{ textAlign: "center" }}>
                                                                <div className="col-xl-12">

                                                                    <div className="row text-center">
                                                                        <div class="loader"></div>
                                                                    </div>
                                                                    <div className="row text-center" style={{ margin: "20px" }}>
                                                                        <p style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}>Mohon Tunggu Data sedang di proses</p>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }



                                                    </>
                                            }
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
